.plugins-popup-popup-content {
  

    .plugins-popup-outer {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        // overflow: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;
        text-align: center;

        .popup-content-inner {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            // overflow: scroll;
            width: 100%;
            margin: auto;

            .plugins-popup {
                font-size: 16px;
            }

            .plugin-outer {
                border: 1px solid #dbebfd;
                border-radius: 5px;
                padding: 10px;

                .plugin-inner {
                    .plugin-image-outer {
                        width: 85px;
                        border: 1px solid #c4c4c4;
                        border-radius: 4px;

                        img {
                            width: 100%;
                        }
                    }

                    .plugin-name {}

                    .plugin-checkbox-outer {}
                }
            }
        }
    }
}


@media (min-width: 320px) and (max-width: 479px) {
    .popup-content-inner {
        width: 115%; // Full width for smaller devices
        margin: 0;
    }

    .plugins-popup {
        font-size: 14px;
    }

    .plugin-outer {
        padding: 6px; // Adjust padding for better fit
        font-size: 12px; // Further adjust font size if needed

        .plugin-inner {
            .plugin-image-outer {
                width: 70px; // Smaller image container for small screens
            }

            .plugin-name,
            .plugin-checkbox-outer {
                font-size: 10px; // Further reduce text size
            }
        }
    }
}

@media (min-width: 480px) and (max-width: 766px) {
    .popup-content-inner {
        width: 95%; // Slightly reduce width for medium-sized screens
        margin: auto;
    }

    .plugins-popup {
        font-size: 15px; // Slightly larger font for better readability
    }

    .plugin-outer {
        padding: 8px; // Adjust padding for medium-sized screens
        font-size: 13px;

        .plugin-inner {
            .plugin-image-outer {
                width: 85px; // Adjust image size for medium screens
            }

            .plugin-name,
            .plugin-checkbox-outer {
                font-size: 11px; // Adjust text size for better fit
            }
        }
    }
}

@media (min-width: 767px) and (max-width: 840px) {
    .popup-content-inner {
        width: 85%; // Reduce width slightly for this range
        margin: auto;
    }

    .plugins-popup {
        font-size: 15px; // Maintain a medium font size
    }

    .plugin-outer {
        padding: 10px;
        font-size: 14px;

        .plugin-inner {
            .plugin-image-outer {
                width: 95px; // Slightly larger image for this range
            }

            .plugin-name,
            .plugin-checkbox-outer {
                font-size: 12px; // Slightly larger text size
            }
        }
    }
}

@media (min-width: 841px) and (max-width: 948px) {
    .popup-content-inner {
        width: 75%; // Reduce width for more compact layout
        margin: auto;
    }

    .plugins-popup {
        font-size: 16px; // Default size for this range
    }

    .plugin-outer {
        padding: 12px; // Maintain standard padding
        font-size: 15px;

        .plugin-inner {
            .plugin-image-outer {
                width: 100px; // Normal image size
            }

            .plugin-name,
            .plugin-checkbox-outer {
                font-size: 13px; // Default text size
            }
        }
    }
}
