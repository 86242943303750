.login-outer {
  height: 100vh;
 
  display: flex;
 

  .login-inner {
    height: 100vh;
    overflow: hidden;
    display: flex;
    width: 100%;
    background-color: white;
    .login-inner-left {
      width: 50%;
      background: #034ab7;
      display: flex;
      align-items: center;
      position: relative;
      .left-logo {
        position: absolute;
        width: 21%;
        top: 5%;
        left: 4%;
      }
    }

    .login-inner-right {
      text-align: center;
      width: 50%;
      display: flex;
      align-items: center;
      .login-sub-inner-right {
        justify-content: center;
        display: flex;
        flex-direction: column;
        margin: auto;
        width: 65%;
        .form-logo-outer {
          text-align: center;
          margin-bottom: 30px;
          .form-logo {
            width: 25%;
          }
        }

        .login-heading {
          font-size: 30px;
          text-align: left;
          font-weight: bold;
        }
        form {
          label {
            font-size: 16px;
            text-align: left;
            margin: 30px 0px 10px 0px;
            color: grey;
            width: 100%;
          }
          .wrapper {
            position: relative;
            display: flex;
            align-items: center;
         
            border: none;
          }
          .icon {
            position: absolute;
            box-sizing: border-box;
            top: 50%;
            left: 15px;
            transform: translateY(-50%);
            font-size: 21px;
          }
          .toggle-icon {
            position: absolute;
            right: 15px;
            top: 50%;
            transform: translateY(-50%);
            font-size: 21px;
            cursor: pointer;
          }
          .input {
            font-size: 16px;
            border: 1px solid #e5e9ef;
            width: 100%;
            width: 100%;
            height: 50px;
            box-sizing: border-box;
            padding-left: 3rem;
            border-radius: 12px;
            outline: none;
          }
          input:-webkit-autofill,
          input:-webkit-autofill:hover,
          input:-webkit-autofill:focus,
          input:-webkit-autofill:active {
            -webkit-box-shadow: 0 0 0 30px white inset !important;
          }
          .terms {
            display: flex;
            align-items: flex-start;
            margin: 20px auto;
            font-weight: 600;

            label {
              font-size: 14px;
              text-align: left;
              margin: 0px;
              margin-left: 10px;
              color: grey;
            }
            input {
              margin-top: 6px;
            }
          }
          .signin-btn {
            width: 50%;
            background-color: #3082ff;
            border: none;
            border-radius: 10px;
            margin: 30px auto;
            padding: 10px;
            color: white;
            font-weight: bold;
            font-size: 17px;
            display: flex;
            align-items: center;

            justify-content: center;
          }

          .signup-outer {
            font-size: 16px;

            .signup {
              color: #007bff !important;
              font-weight: bold;
              text-decoration: none !important;
            }
          }
        }
      }
    }

    .email-verification-outer {
      display: flex;
      .email-verification-inner {
        margin: auto;

        .email-text {
          font-size: 15px;
          margin: 20px auto 40px auto;
        }
        .email-image {
          width: 30%;
          margin: 40px;
        }
        .signup-outer {
          font-size: 16px;

          a {
            color: #007bff !important;
            text-decoration: none !important;
          }
        }
      }
    }
  }
}

@media (max-width: 340px) {
}
@media (max-width: 520px) {
  .login-outer .login-inner {
    .login-inner-right {
      padding-left: 0%;
      padding-right: 0%;
    }
  }
}

@media (min-width: 320px) and (max-width: 520px) {
  .login-outer {
    .login-inner {
      .login-inner-right {
        padding-left: 0%;
        padding-right: 0%;
        .login-sub-inner-right {
          justify-content: center;
          display: flex;
          flex-direction: column;
          margin: auto;
          width: 100%;
          .signin-btn {
            font-size: 14px;
            padding: 5px 20px;
          }
          .form-logo-outer {
            text-align: center;
            width: 100px;
            margin: auto;
            margin-bottom: 30px;
            .form-logo {
              width: 100%;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .login-outer {
    .login-inner {
      .login-inner-left {
        margin: 0px;
        padding: 0px;
        display: none;
      }
      .login-inner-right {
        width: 70%;
        top: 10px;
        margin: 50px auto;
        display: block;
      }
      .form-logo-outer {
        .form-logo {
          width: 50% !important;
        }
      }
    }
  }
}

/* For screens between 768px and 1024px (e.g., tablets and small laptops) */
@media (min-width: 768px) and (max-width: 1023px) {

  .login-outer {
    .login-inner {
      .login-inner-left {
        display: block;
        img {
          margin: 100px auto !important;
          display: flex;
        }
        .left-logo {
          position: absolute;
          width: 35%;
          margin: 20px auto !important;
          top: 0%;
          left: 2%;
        }
      }
      .login-inner-right {
        width: 60%;
        top: 10px;
        margin: 100px auto;
        display: block;
        .login-sub-inner-right {
          .signin-btn {
            font-size: 14px;
            padding: 5px 20px;
          }
        }
      }
      .form-logo-outer {
        display: none;
        .form-logo {
          width: 50%;
        }
      }
    }
  }
}

/* For screens between 1024px and 1280px (e.g., laptops and desktops) */
@media (min-width: 1024px) and (max-width: 1279px) {

  .login-outer {
    .login-inner {
      .login-inner-left {
        display: block;
        img {
          margin: 100px auto !important;
          display: flex;
        }
        .left-logo {
          position: absolute;
          width: 35%;
          margin: 20px auto !important;
          top: 0%;
          left: 2%;
        }
      }
      .login-inner-right {
        display: block;
        .login-sub-inner-right {
          margin: 100px auto !important;
          .form-logo-outer {
            display: none;
          }
        }
      }
    }
  }
}
/* For screens larger than 1280px (e.g., larger desktop monitors and beyond) */
@media (min-width: 1280px) {

  .login-outer .login-inner .login-inner-right .form-logo-outer {
    display: none;
  }
}
