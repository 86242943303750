.layout-inner {

    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;

    .channel-member-container-outer {
        flex-grow: 1;
        padding: 20px;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        background: white;
        margin: 0px 10px 20px 10px;

        .page-heading {
            text-align: center;
        }

        .functionality-outer {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 20px;

            .directory-outer {
                display: flex;
                align-items: center;

                .back-directory {
                    margin-right: 10px;
                    border: none;
                    background: rgb(87, 166, 250);
                    color: white;
                    font-weight: bold;
                    border-radius: 6px;
                }
                .back-directory-disabled {
                    pointer-events: none;
                    background: rgb(193 223 255);
                }

                .track-directory-list {
                    font-weight: bold;
                    .directory-name {
                        font-weight: bold;
                    }
                }
            }
            .add-channel-btn {
                margin-right: 10px;
                border: none;
                color: rgb(87, 166, 250);
                font-weight: bold;
                border-radius: 6px;
            }
        }

        .channel-list-outer {
            margin: 20px;
            padding: 10px;
            border: 1px solid gainsboro;
            border-radius: 4px;

            .channel-list-heading-outer {
                padding: 10px;
                font-weight: bold;

                .channel-list-heading {}
            }

            .channel-list-item-outer {
                margin: 5px auto;
                padding: 10px;
                background: #57a6fa12;
                align-items: center;
                border-radius: 5px;

                .channel-list-item {}

                .channel-list-item-manage {
                    text-align: center;

                    .manage-channel-btn {
                        border: none;
                        border-radius: 5px;
                        background: rgb(87 166 250);
                        color: white;
                        font-weight: bold;
                    }
                    
                }
            }

            .channel-list-item-outer:hover {
                background: rgb(87 166 250 / 21%);
            }
        }
    }
}