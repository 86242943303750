.outer-landing-navbar {
    background: #fffffff0;
    position: fixed !important;
    right: 0;
    left: 0;
    width: 100%;
    z-index: 1;
    border: 1px solid #ebebeb;
    padding: 10px 12px !important;

    .landing-navbar {
        font-size: 16px;
        max-width: 100%;

        .left-hamburger-icon {
            background: transparent;
            border: none;
        }

        .logo-image {
            height: 42px;
        }

        .login-btn {
            text-decoration: none;
            color: white;
            background: #329fef;
            padding: 6px 17px;
            font-size: 15px;
        }

        .nav-link {
            display: flex;
            align-items: center;
            padding: 3px 10px;
        }

        .nav-link:hover {
            background: #efefef;
            padding: 3px 10px;
            border-radius: 5px;
        }

        .nav-link:visited {
            color: inherit;
            font-size: 18px;
        }

        .user-dropdown-image {
            width: 40px;
            border-radius: 50%;
            border: 1.8px solid rgb(205, 205, 205);
        }

        .dropdown-menu {
            right: 0;
            left: auto;
            min-width: auto !important;
            font-size: 16px;
        }
    }
}



.layout2-inner {
    display: flex;
    height: 100vh;
   


    .layout2-inner-sidebar {
        
        display: flex;
        background-color: #ffffff;
        z-index: 12;
        

        .sidebar-collapse-button {
            position: absolute;
            right: 15px;
            top: 10px;
            height: 30px;
            width: 30px;
            background: white;
            border: none;
            border-radius: 50%;
            box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
        }

        .ps-sidebar-root {
            height: 100%;
          
            border: none;

            .ps-sidebar-container {
                display: flex;
                flex-direction: column;
                background: #378ae7;
              
                .greetings {
                  font-size: 20px;
                  margin-bottom: 20px;
                }
              
                .ps-menu-root {
                  flex-grow: 1;
                  display: flex;
                  flex-direction: column;
              
                  .css-ewdv3l {
                    display: flex;
                    flex-direction: column;
                    flex-grow: 1;
                    height: 100%;
                  }
              
                  .ps-menu-button {
                    display: flex;
                    align-items: center;
                  }
                }
              
                .sidebar-heading {
                  color: grey;
                  font-size: 15px;
                  padding: 0px 20px;
                  margin-bottom: 10px;
                  color: rgb(154, 170, 187);
                }
              
                .sidebar-channels-outer {
                  overflow: scroll;
                  flex-grow: 1;
                  -ms-overflow-style: none;
                  scrollbar-width: none;
                }
              
                .no-organisation {
                  background: #e0e6ff;
                  color: #779cf6;
                  text-align: center;
                  width: fit-content;
                  margin: auto;
                  margin-top: 10px;
                  border-radius: 10px;
                  padding: 0px 10px;
                }
              
                .organisation-outer {
                  display: flex;
                  align-items: center;
                  padding: 10px;
              
                  .organisation-name {
                    flex-grow: 1;
                    font-weight: bold;
                  }
              
                  .organisation-logo-outer {
                    height: 50px;
                    width: 50px;
              
                    .organisation-logo {
                      width: 100%;
                    }
                  }
                }
              
                .menu-item {
                  margin: 0px auto;
                  color: white;
                  transition: background-color .5s;
                  background-color: #378ae7;
                  padding: 10px 20px;
                }
                .link-active {
                    background-color: #0076f4;
                }
              
                .link-active {
                  background: #f6f5f8 !important;
                  color: rgb(23, 124, 255) !important;
                }
              
                /* Target the first inactive link after the active one */
                .link-active + li .link-inactive {
                    border-radius: 0 8px 8px 0;
                }

                /* Target the last inactive link before the active one */
                li .link-inactive + .link-active {
                    border-radius: 8px 0 0 8px;
                }
              
                .menu-item:hover {
                  background: #dce2ec;
              
                //   .ps-menu-button:hover {
                //     background: transparent;
                //   }
                }
              
                .channel-link {
                  color: #2a2a2a;
                  text-decoration: none;
                  font-weight: bold;
              
                  .channel-outer {
                    .ps-menu-button {
                      height: 40px;
                    }
              
                    .ps-menu-button:hover {
                      background-color: #0563c9 !important;
                      color: white !important;
                    }
                  }
              
                  .link-active {
                    background: #0076f4 !important;
                    color: white !important;
                  }
                }
              }
              
        }
    }

    .layout2-inner-main {
        width: 100%;
        position: relative;
        overflow: hidden;
        background-color: #f6f5f8;
        display: flex;
    }
}

.sidebar-header{
  margin-top: 20px;
 display: flex;
 justify-content: center;
}

.sidebar-logo {
  margin: auto;
  height: 30px;
  background-color: white;
  border-radius: 5px;
  width: 70px;
  }
  
  .sidebar-logo img {
    height: 26px;
    background: white;
    border-radius: 5px;
  }
  
  .sidebar-logo span {
    margin-left: 10px;
    font-weight: bold;
  }
  
  .sidebar-collapse-button {
    margin-top: 10px;
  }
  
  .sidebar-divider {
    color: white;
    opacity: 0.7;
  }
  
  .sidebar-link-container {
    background-color: #f6f5f8;
  }
  
  .menu-item {
    display: flex;
    align-items: center;
  }
  
  .menu-item svg {
    margin-right: 10px;
  }
  
  .sidebar-active-link {
    background-color: #e0e0e0;
  }
  
  .sidebar-inactive-link {
    background-color: transparent;
  }
  
  .layout-content {
    margin-top: 70px;
    height: calc(100% - 60px);
    width: 100%;
    flex-grow: 1;
    overflow: scroll;
  }
  
  .navbar-user-dropdown img {
    height: 30px;
    border-radius: 50%;
    margin-right: 5px;
  }
  
  .navbar-user-dropdown span {
    font-weight: bold;
  }
  



.footer {
   
    text-align: center;
    background-color: black;
    color: white;

    .social-icon {
        margin: 20px;

        .social-svg {
            background-color: white;
        }
    }

    .footer-links-outer {
        color: white;
        display: flex;
        justify-content: space-around;
        font-size: 14px;
        width: 70%;
        margin: 20px auto;
    }

    .copyright-text {
        font-size: 15px;
        color: grey;
    }
}

@media screen and (max-width: 319px) {
    .layout2-inner {

        .layout2-inner-sidebar {
          
            position: absolute;
            background: white;
            height: 100vh;
            z-index: 1111;
            
            width: 0px;
            overflow: hidden;
            transition: all .5s;
            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

            &.sidebar-expanded {
              width: 250px;
            }
        }
    }
}


@media screen and (min-width: 320px) and (max-width: 480px) {
    .layout2-inner {

        .layout2-inner-sidebar {
           
            position: absolute;
            background: white;
            height: 100vh;
            z-index: 1111;
           
            width: 0px;
            overflow: hidden;
            transition: all .5s;
            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        }
    }
}

@media screen and (max-width: 575.98px) {
    .layout2-inner {

        .layout2-inner-sidebar {
           
            position: absolute;
            background: white;
            height: 100vh;
            z-index: 1111;
           
            width: 0px;
            overflow: hidden;
            transition: all .5s;
            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        }
    }
}

@media screen and (min-width: 481px) and (max-width: 767.98px) {
    .layout2-inner {

        .layout2-inner-sidebar {
         
            position: absolute;
            background: white;
            height: 100vh;
            z-index: 1111;
          
            width: 0px;
            overflow: hidden;
            transition: all .5s;
            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        }
    }
}

@media screen and (min-width: 768px) and (max-width: 991.98px) {
    .layout2-inner {

        .layout2-inner-sidebar {
           
            position: absolute;
            background: white;
            height: 100vh;
            z-index: 1111;
            
            width: 0px;
            overflow: hidden;
            transition: all .5s;
            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        }
    }
}

@media screen and (min-width: 992px) and (max-width: 1200px) {
    .layout2-inner {

        .layout2-inner-sidebar {
            position: absolute;
            background: white;
            height: 100vh;
            z-index: 1111;
        
            width: 0px;
            overflow: hidden;
            transition: all .5s;
            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
           
        }
    }
}

@media screen and (min-width: 1201px) and (max-width: 1599px) {
    .outer-landing-navbar .landing-navbar .left-hamburger-icon {
        display: none;
    }

    .layout2-inner {
        .layout2-inner-sidebar {
            .sidebar-collapse-button {
                display: none;
            }
        }
    }
}

@media screen and (min-width: 1600px) {
    .outer-landing-navbar .landing-navbar .left-hamburger-icon {
        display: none;
    }

    .layout2-inner {
        .layout2-inner-sidebar {
            .sidebar-collapse-button {
                display: none;
            }
        }
    }
}

@media (min-width: 320px) and (max-width: 479.5px) {
   
}

@media (min-width: 480px) and (max-width: 767px) {
   
}

@media (min-width: 767px) and (max-width: 992px) {
  
}