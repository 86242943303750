body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.popup-content {
  padding: 15px !important;
  border-radius: 5px;
  max-height: 90vh;
  display: flex;
  flex-direction: column;
}
.popup-content .close-btn {
 
  font-size: 25px;
  position: absolute;
  right: 14px;
  top: 5px;
  font-weight: 600;
  border: none;
  background: transparent;
  border-radius: 4px;
 
  color: #333333;
}
.popup-content .popup-heading {
  text-align: center;
  font-weight: bold;
  font-size: 22px;
  color: rgb(45 140 241);
}

.popup-content-inner {
  margin-top: 20px;
  padding: 20px;
}
.popup-content .popup-content-functionality-btn {
  margin: 20px;
  text-align: center;
}
.popup-content .popup-content-functionality-btn .submit-btn {
  background: rgb(45 140 241);
  color: white;
  border: none;
  font-size: 20px;
  border-radius: 5px;
  font-weight: bold;
  padding: 2px 10px;
}
.popup-content .popup-content-functionality-btn .cancel-btn {
  background: rgb(244, 25, 25);
  color: white;
  border: none;
  font-size: 20px;
  border-radius: 5px;
  font-weight: bold;
  padding: 2px 10px;
}

.popup-content .popup-content-functionality-btn .submit-btn-disabled {
  background: rgb(176, 210, 247);
  pointer-events: none;
}
a {
  color: inherit !important;
  text-decoration: none !important;
}