.event-input {
    label {
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 8px;
      display: block;
    }
  
    .members-images {
      display: flex;
      align-items: center;
      margin-top: 10px;
  
      .member-image-wrapper {
        position: relative;
        margin-right: 8px;
  
        .member-profile-image {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          object-fit: cover;
          border: 2px solid #ddd;
        }
  
        .remove-icon {
          position: absolute;
          top: 0px;
          right: 0px;
          background-color: white;
          color: rgb(139, 139, 139);
          border-radius: 50%;
          font-size: 12px;
          cursor: pointer;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        }
      }
  
      .main-member-count {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: #f0f0f0;
        font-size: 14px;
        font-weight: bold;
        color: #333;
      }
    }
  
    .option-label {
      display: flex;
      align-items: center;
  
      .members-profile-picture {
        width: 25px;
        height: 25px;
        border-radius: 50%;
        object-fit: cover;
        margin-right: 8px;
      }
  
      .option-label-text {
        font-weight: bold;
      }
    }
  }
  