.home-page-layout-inner {
  padding-top: 74px;
  overflow: hidden;
  // width: 30.8rem;

  .section-1 {
    display: flex;
  }

  .main-content-landing {
    flex-grow: 1;
    overflow: hidden;

    .section-1 {
      position: relative;

      .header {
        justify-content: space-between;
        margin-bottom: 30px;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        text-align: center;
        display: flex;
        margin: auto;
        width: 100%;
        flex-direction: column;
        padding: 30px;
        width: 80%;

        .team-control {
          display: flex;
          align-items: center;
          padding: 0.5vw 1vw;;
          background-color: #f5faff;
          border-radius: 30px;
          border: 2px solid #a4d4ff;
          box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

          .avatars {
            display: flex;
            margin-right: 8px;

            .avatar {
              width: 32px;
              height: 32px;
              border-radius: 50%;
              border: 2px solid white;
              box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
              margin-left: -8px;

              &:first-child {
                margin-left: 0;
              }
            }
          }

          .team-text {
            font-size: 1vw; 
            color: #4a4a4a;
            font-weight: 500;
          }
        }


        .header-left {
          width: 63%;
          margin: auto;
          margin-top: 10px;

          h1 {
            font-size: 3.6vw;
            font-weight: bold;
            color: #333;
            margin-bottom: 10px;
          }

          p {
            font-size: 1.5vw;

          }
        }

      }

      .images-container {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 20px;
        width: 100%;

        .image-item {
       
          border-radius: 8px;
          text-align: center;
          width: 100%;

          img {
            max-width: 100%;
            width: 100%;
            height: auto;
            border-radius: 5px;
          }
        }
      }
    }

    .key-features-container {
      display: inline-block;
      background-color: rgba(173, 216, 230, 0.2);
      border-radius: 20px;
      padding: 5px 15px;
    

      .key-features-text {
        font-size: 14px;
        font-weight: bold;
        color: #87CEFA;
        text-transform: uppercase;
      }
    }

    .features-section {
      padding: 40px 100px;
      background-color: #f9f9f9;

      .feature {
      
        margin: 0 auto;
        text-align: center;

        .feature-text {
          margin-bottom: 40px;
          display: flex;
          justify-content: space-between;

          h2 {
            font-size: 48px;
            font-weight: 700;
            color: #333;
            width: 55%;
            text-align: left;
          }

          p {
            margin-top: 5px;
            width: 40%;
            font-size: 20px;
            color: #666;
            text-align: left;
            font-weight: 400;
          }
        }

        .feature-details {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          gap: 30px 0px;

          .feature-item {
            width: 48%;
           
            text-align: left;
            transition: transform 0.3s ease, box-shadow 0.3s ease;

            &:hover {
              transform: translateY(-10px);
            }

            img {
              max-width: 100%;
              height: auto;
              border-radius: 10px;
              margin-bottom: 15px;
            }

            h3 {
              font-size: 22px;
              color: #333;
              margin-bottom: 10px;
            }

            p {
              font-size: 16px;
              color: #666;
            }
          }
        }
      }
    }

    .features-container {
      display: flex;
      flex-wrap: wrap;
      gap: 0px;
      justify-content: center;
      align-items: center;
      padding: 20px;
      background-color: #f9fafc;
   

      .features-text {
        text-align: left;
        width: 48%;

        .text-heading {
          border-radius: 20px;
          color: #32A0EF;
          background: #e2f1fc;
          font-size: 15px;
          font-weight: 600;
          margin-bottom: 10px;
          width: min-content;
          white-space: nowrap;
          padding: 5px 10px;

        }

        .features-heading {
          h2 {
            color: #000000;
            font-size: 38px;
            font-weight: bold;
            margin: 0;

            strong {
              color: #32A0EF;
            }
          }

          p {
            font-size: 26px;
            margin-top: 10px;
            color: #333333;
          }
        }
      }

      .features-image {
        margin-top: 20px;

        img {
          width: 80%;
          height: auto;
          object-fit: cover;
        }
      }

      .features-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 1px;


        .feature-item {
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #eef7ff;
          padding: 3px;
          margin: 23px;
          border-radius: 10px;
          width: 250px;
          text-align: center;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

          img {
            margin-right: 7px;
          }

          .feature-icon {
            font-size: 20px;
            margin-right: 10px;
            color: #2c5bff;
          }

          p {
            font-size: 16px;
            color: #333;
            margin: 0;
          }
        }
      }
    }

    .help-section {

      bottom: 20px;
      left: 0;
      right: 0;
      text-align: center;

      img {
        width: 100%;
      }
    }
  }
}


// media queries //

@media screen and (min-width: 320px) and (max-width: 360px) {
  .home-page-layout-inner {
    padding-top: 50px;

    .section-1 {
      flex-direction: column;
    }

    .main-content-landing {
      .section-1 {
        .header {
          flex-direction: column;
          text-align: center;

          .header-left {
            width: 95%;
            h1 {
              font-size: 22px;
              margin-bottom: 10px;
            }
            p {
              font-size: 12px;
              margin-bottom: 15px;
              line-height: 1.5;
            }
          }
        }

        .images-container {
          flex-direction: column;
          .image-item {
            width: 100%;
            margin-bottom: 10px;

            img {
              width: 100%;
              height: auto;
              border-radius: 5px;
            }
          }
        }
      }

      .key-features-container {
        margin: 10px auto;
        padding: 10px;
        border-radius: 10px;
        .key-features-text {
          font-size: 12px;
        }
      }

      .features-section {
        padding: 20px;

        .feature {
          .feature-text {
          
            h2 {
              font-size: 17px;
            }
            p {
              font-size: 8px;
              line-height: 1.4;
            }
          }

          .feature-details {
            .feature-item {
              width: 100%;
              margin-bottom: 20px;

              img {
                width: 100%;
                border-radius: 8px;
              }

              h3 {
                font-size: 18px;
              }

              p {
                font-size: 14px;
              }
            }
          }
        }
      }

      .features-container {
        padding: 10px;

        .features-text {
          .features-heading {
            h2 {
              font-size: 20px;
              margin-bottom: 10px;
            }
            p {
              font-size: 14px;
              margin-bottom: 15px;
            }
          }
        }

        .features-list {
          .feature-item {
            width: 100%;
            margin: 10px 0;
            padding: 10px;
            font-size: 14px;
          }
        }
      }

      .help-section {
        margin-top: 20px;
        img {
          width: 100%;
          height: auto;
        }
      }
    }
  }
}


@media screen and (min-width: 361px) and (max-width: 480px) {
  .home-page-layout-inner {
    padding-top: 64px;

    .header-left h1 {
      font-size: 16px;
    }

    .header-left p {
      font-size: 10px;
    }

    .images-container .image-item {
      width: 47%;
    }

    .features-section h2 {
      font-size: 35px;
    }
    .main-content-landing {
      .section-1 {
        .header {
          .header-left {
            width: 80%;
          }
        }
      }
      .features-section {
        text-align: center;
        padding: 40px 30px;
        .feature {
          .feature-text {
            display: block;
            h2 {
              width: 100%;
              text-align: center;
              font-size: 30px;
            }
            p {
              width: 100%;
            }
          }
          .feature-details {
            .feature-item {
              width: 100%;
            }
          }
        }
      }
      .features-container {
        margin-left: 0px;
        .features-text {
          width: 90%;
          text-align: center;
          .text-heading {
            margin: auto;
          }
        }
        .features-image {
          text-align: center;
        }
      }
    }
  }
}

@media screen and (min-width: 481px) and (max-width: 575.98px) {
  .home-page-layout-inner {
    padding-top: 64px;

    .header-left h1 {
      font-size: 16px;
    }

    .header-left p {
      font-size: 10px;
    }

    .images-container .image-item {
      width: 47%;
    }

    .features-section h2 {
      font-size: 35px;
    }
    .main-content-landing {
      .section-1 {
        .header {
          .header-left {
            width: 80%;
          }
        }
      }
      .features-section {
        text-align: center;
        padding: 40px 30px;
        .feature {
          .feature-text {
            display: block;
            h2 {
              width: 100%;
              text-align: center;
              font-size: 30px;
            }
            p {
              width: 100%;
            }
          }
          .feature-details {
            .feature-item {
              width: 100%;
            }
          }
        }
      }
      .features-container {
        margin-left: 0px;
        .features-text {
          width: 90%;
          text-align: center;
          .text-heading {
            margin: auto;
          }
        }
        .features-image {
          text-align: center;
        }
      }
    }
  }
}


@media screen and (min-width: 576px) and (max-width: 767.98px) {
  .home-page-layout-inner {
    padding-top: 64px;

    .header-left h1 {
      font-size: 16px;
    }

    .header-left p {
      font-size: 10px;
    }

    .images-container .image-item {
      width: 47%;
    }

    .features-section h2 {
      font-size: 35px;
    }
    .main-content-landing {
      .section-1 {
        .header {
          .header-left {
            width: 80%;
          }
        }
      }
      .features-section {
        text-align: center;
        padding: 40px 30px;
        .feature {
          .feature-text {
            display: block;
            h2 {
              width: 100%;
              text-align: center;
              font-size: 30px;
            }
            p {
              width: 100%;
            }
          }
          .feature-details {
            .feature-item {
              width: 100%;
            }
          }
        }
      }
      .features-container {
        margin-left: 0px;
        .features-text {
          width: 90%;
          text-align: center;
          .text-heading {
            margin: auto;
          }
        }
        .features-image {
          text-align: center;
        }
      }
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 991.98px) {
  .home-page-layout-inner {
    padding-top: 74px;

    .header-left h1 {
      font-size: 60px;
    }

    .header-left p {
      font-size: 25px;
    }

    .images-container .image-item {
      width: 48%;
    }

    .features-section h2 {
      font-size: 42px;
    }
  }
}

@media screen and (min-width: 992px) and (max-width: 1200px) {
  .home-page-layout-inner {
    padding-top: 78px;

    .header-left h1 {
      font-size: 2.6rem;
    }

    .header-left p {
      font-size: 26px;
    }

    .images-container .image-item {
      width: 30%;
    }

    .features-section h2 {
      font-size: 45px;
    }
  }
}

@media screen and (min-width: 1201px) {
  .home-page-layout-inner {
    padding-top: 80px;

    .header-left h1 {
      font-size: 65px;
    }

    .header-left p {
      font-size: 28px;
    }

    .images-container .image-item {
      width: 30%;
    }

    .features-section h2 {
      font-size: 48px;
    }
  }
}