.layout-inner {
  display: flex;
  width: 155%;
  flex-direction: column;
  justify-content: center;

  .member-container-outer {
    flex-grow: 1;
    padding: 20px;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  
    .member-container-inner {
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      background: white;
    }
    .nav-tabs {
      display: none;
    }

    .heading-outer {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      margin: 10px auto;
      padding: 10px;
      border-radius: 5px;

      .page-heading {
        font-size: 24px;
        font-weight: bold;
      }

      .upload-members-btn {
        background: #096ca7;
        border: none;
        padding: 5px 10px;
        color: white;
        border-radius: 5px;
      }
      .upload-member-history-btn {
        margin-right: 10px;
        background-color: #44adec;
      }
    }

    .table-outer {
      width: 100%;
      margin: 10px auto;
      border-radius: 5px;
      padding: 10px;
      flex-grow: 1;
      overflow: scroll;
      -ms-overflow-style: none;
      scrollbar-width: none;
      display: flex;
      flex-direction: column;
      .table {
        flex-grow: 1;
        overflow: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;
        border-radius: 8px;
        overflow: hidden;
      }

      thead {
        background: #ecf6ff;
        color: white;
        border-top: 2px solid #469bd6;

        th {
          background: rgb(202, 231, 250);
          color: black;
          font-weight: bold;

        }
      }

      tr {
        border-bottom: 1px solid #e4e4e4;
        height: 50px;

        .active-status-outer {
          .active-status {
            border: none;
            color: #069c42;
            background: #c2fad8;
            border-radius: 5px;
          }
          .pending-status {
            border: none;
            color: #ff701f;
            background: #ffe6d8;
            border-radius: 5px;
          }
        }
        .action-btns {
          font-size: 18px;

          .action-btn {
            background: none;
            border: none;
            color: grey;
            margin: auto 2px;
            border-radius: 5px;
          }
          .action-btn:hover {
            background: #efefef;
          }
        }
      }
      .table-functions {
        margin: 20px 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .table-functions-dropdowns {
          display: flex;
          .dropdown-item {
            margin: 10px;
          }
        }
        .search-input {
          border: 1px solid #c1c1c1;
          border-radius: 5px;
          padding: 5px;
          outline: none;
        }
      }
    }

    .tab-content > .tab-pane {
      flex-grow: 1;
      opacity: 1;
      display: block;
      position: relative;
      overflow: hidden;
      max-width: 0px;
      max-height: 0px;
      opacity: 0;
      transition: max-width 1s, max-height 0.002s, opacity 1.5s;
    }

    .tab-content {
      height: 100%;
      min-width: 50vw;
      display: flex;
      flex-direction: column;

      .tab {
        align-items: center;
      }

      .tab-heading {
        width: 100%;
        background: #3f73f2;
        color: white;
        font-size: 21px;
        padding: 26px;
        font-weight: bold;
      }

      .tab3 {
        .organisation-name-outer {
          min-width: 600px;

          .organisation-name-label {
            text-align: left;
          }
        }
      }

      .organisation-name-outer {
        height: 100%;
        margin: 20px auto;
        display: flex;
        flex-direction: column;
        text-align: left;
        min-width: 800px;

        .organisation-name-label-sub {
          margin-top: 0px;
          margin-bottom: 50px;
        }

        .organisation-name-label {
          text-align: center;
          font-weight: bold;
          margin-bottom: 20px;
        }

        .input-field {
          margin-bottom: 20px;
          border: none;
          width: 60%;
          border-bottom: 1px solid grey;
        }

        .input-field:focus-visible {
          outline: none;
        }
      }

      .nav-buttons-outer {
        width: 80%;
        margin: 20px;
        display: flex;
        justify-content: space-between;

        .nav-btn {
          border: none;
          border-radius: 16px;
          background: #0671ff;
          color: white;
          min-width: 77px;
          font-size: 20px;
        }
      }
    }

    .tab-content > .active {
      opacity: 1;
      max-height: 2000px;
      max-width: 2000px;
  
    }

    .table-striped > tbody > tr:nth-of-type(odd) > * {
      --bs-table-color-type: rgba(87, 166, 250, 0.0705882353);
      --bs-table-bg-type: rgba(87, 166, 250, 0.0705882353);
      color: rgb(14, 35, 47) !important;
    }
  }
}

@media (min-width: 320px) and (max-width: 479px) {
  .layout-inner {
    .member-container-outer {
      margin: 20px 0px 0px 0px;
      .heading-outer {
        font-size: 12px;
        .page-heading {
          font-size: 18px;
        }
        .plug-fail-btn{
            height: 26px;
                display: flex;
          }
          .upload-members-btn{
            height: 26px;
            display: flex;
          }
      }
      .table-outer {
        .action-btns {
          font-size: 10px;
          display: flex;
        }
        .table-functions {
          justify-content: space-evenly;
          .search-input {
            width: 100px;
            text-align: center;
          }
        }
        .table {
          font-size: 10px;
        }
      }
    }
  }
}

@media (min-width: 479px) and (max-width: 606px) {
  .layout-inner {
    .member-container-outer {
      margin: 20px 0px 0px 0px;
      .heading-outer {
        font-size: 12px;
        .page-heading {
          font-size: 18px;
        }
        .plug-fail-btn{
            height: 26px;
                display: flex;
          }
          .upload-members-btn{
            height: 26px;
            display: flex;
          }
      }
      .table-outer {
        .action-btns {
          font-size: 10px;
          display: flex;
        }
        .table-functions {
          justify-content: space-evenly;
        }
        .table {
          font-size: 10px;
        }
      }
    }
  }
}

@media (min-width: 605px) and (max-width: 992px) {
  .layout-inner {
    .member-container-outer {
      margin: 20px 0px 0px 0px;
      .heading-outer {
        font-size: 12px;
        .page-heading {
          font-size: 18px;
        }
        .plug-fail-btn{
            height: 26px;
                display: flex;
          }
          .upload-members-btn{
            height: 26px;
            display: flex;
          }
      }
    }
  }
}
