n.chat-container {
  height: calc(100% - 30px);
  background: white;
  margin: 10px;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  background: white;
  border-radius: 5px;
  // padding: 15px;
  #chat-log {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: end;
    margin-bottom: 0px;

  }

  .contact-profile {
    width: 100%;
    padding: 10px 15px;
    border-bottom: 1px solid #dbdbdb;
    // height: 80px;
    // line-height: 80px;
    // background: #f6f8fa;
  }

  .contact-profile img {
    width: 60px;
    border-radius: 50%;
    float: left;
    margin: 9px 12px 0 9px;
  }

  .contact-profile p {
    float: left;
    font-size: 18px;
  }

  .contact-profile .channel-header {
    display: flex;
    width: 100%;
    justify-content: space-between;

    .details-btn-outer {
      line-height: initial;
      display: flex;
      align-items: center;

      .details-btn {
        border: none;
        padding: 10px;
        margin-right: 20px;
        font-weight: bold;
        border-radius: 5px;
        background: none;
        // color: rgb(101, 141, 176);
        font-size: 21px;
      }
      .add-channel-btn {
        border: none;
        padding: 10px;
        margin-right: 20px;
        font-weight: bold;
        border-radius: 5px;
        background: #eef2f8;
        color: rgb(101, 141, 176);
        font-size: 16px;
      }
    }
  }

  .contact-profile .social-media {
    float: right;
  }

  .contact-profile .social-media i {
    margin-left: 14px;
    cursor: pointer;
  }

  .contact-profile .social-media i:nth-last-child(1) {
    margin-right: 20px;
  }

  .contact-profile .social-media i:hover {
    color: #435f7a;
  }

  .messages {
    // margin-bottom: 30px;
    height: auto;
    flex-grow: 1;
    // min-height: calc(100% - 93px);
    // max-height: calc(100% - 147px);
    overflow-y: scroll;
    overflow-x: hidden;
    width: 100%;
  }
  .message-input .not-allowed-chat {
    width: 100%;
    text-align: center;
    box-shadow: rgb(19 19 19 / 20%) 0px 2px 8px 0px;
    padding: 11px 32px 10px 8px;
    background: #e9e9e9;
    color: #8c8c8c;
  }

  @media screen and (max-width: 735px) {
    .messages {
      max-height: calc(100% - 105px);
    }
  }

  .messages::-webkit-scrollbar {
    width: 8px;
    background: transparent;
  }

  .messages::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.3);
  }

  .messages ul li {
    display: inline-block;
    clear: both;
    float: left;
    // margin: auto 15px auto 15px;
    width: calc(100% - 25px);
    // margin-top: auto;
    font-size: 0.9em;
  }

  .messages ul li:nth-last-child(1) {
    margin-bottom: 20px;
  }

  .messages ul li.sent {
    position: relative;
  }

  .messages ul li.sent img {
    margin: 6px 8px 0 0;
    margin: auto;
  }

  .messages ul li.sent .image-outer {
    position: absolute;
    display: flex;
    left: 0px;
    // bottom: 20px;
    align-items: self-start;
  }

  .messages ul li.replies {
    position: relative;
  }

  .messages ul li.replies .image-outer {
    position: absolute;
    display: flex;
    right: 0px;
    bottom: 20px;
    align-items: self-start;
  }

  .messages ul li.sent p {
    margin-left: 50px;
    // background: #f6f8fa;
    border-bottom-left-radius: 0px;
  }
  .messages ul .message-outer .edit-action-btn{
    padding: 0px;
    background: #33d727;
    border-radius: 50%;
    width: 22px;
    height: 22px;
    color: white;
    font-size: 12px;
    position: relative;
    border: none;
    margin: 0px 5px; 
  }
  .messages ul .message-outer .edit-action-btn-save{
    background: #33d727;
  }
  .messages ul .message-outer .edit-action-btn-cancel{
    background: #ff1818;
  }
  .messages ul .message-outer .edit-message {
    border: 1.5px solid #bfbfbf;
    padding: 5px;
    width: 100%;
    border-radius: 5px;
  }
  .messages ul .message-outer span {
    font-size: 15px;
  }
  .messages ul .replies .message-outer .email {
    .edit-btn {
      border: none;
      background: none;
      color: #c6e2ff;
    }
  }
  .messages ul .sent .message-outer .email {
    // color: #7d8f9b;
    .edit-btn {
      border: none;
      background: none;
      color: grey;
    }
  }

  .messages ul li.replies img {
    float: right;
    margin: 6px 0 0 8px;
    margin: auto;
  }

  .messages ul li.replies p {
    margin-right: 50px;
    // background: #0076f4;
    text-align: right;
    float: right;
    border-bottom-right-radius: 0px;
  }

  .messages ul li img {
    width: 42px;
    border-radius: 50%;
    float: left;
  }

  .messages ul li p {
    display: inline-block;
    padding: 0px 10px 15px;
    border-radius: 20px;
    max-width: 300px;
    line-height: 130%;
    font-size: 18px;
  }

  @media screen and (min-width: 735px) {
    .messages ul li p {
      max-width: 70%;
    }
  }

  .message-input {
    padding: 15px;
    bottom: 0;
    width: 100%;
    z-index: 99;
  }

  .message-input .wrap {
    position: relative;
    display: flex;
    justify-content: center;
    border-radius: 50px;
    background: #f7f7f7;
    #chat-message-submit {
      border-radius: 50px;
    }
  }

  .message-input .wrap input {
    font-family: "proxima-nova", "Source Sans Pro", sans-serif;
    float: left;
    border: none;
    width: calc(100% - 50px);
    background: #f7f7f7;
    padding: 5px 18px;
    padding: 11px 32px 10px 8px;
    font-size: 17px;
    color: #445e92;
    border-radius: 50px;
  }

  @media screen and (max-width: 735px) {
    .message-input .wrap input {
      padding: 15px 32px 16px 8px;
    }
  }

  .message-input .wrap input:focus {
    outline: none;
  }

  .message-input .wrap .attachment {
    display: none;
    position: absolute;
    right: 60px;
    z-index: 4;
    margin-top: 10px;
    font-size: 1.8em;
    color: #e2e2e2;
    opacity: 0.5;
    cursor: pointer;
  }

  @media screen and (max-width: 735px) {
    .message-input .wrap .attachment {
      margin-top: 19px;
      right: 65px;
    }
  }

  .message-input .wrap .attachment:hover {
    opacity: 1;
  }

  .message-input .wrap button {
    /* float: right; */
    border: none;
    width: 50px;
    padding: 12px 0;
    cursor: pointer;
    background: #0076f4;
    color: #f6f8fa;
  }

  @media screen and (max-width: 735px) {
    .message-input .wrap button {
      padding: 21px 0;
    }
  }

  .message-input .wrap button:hover {
    background: #e2e2e2;
  }

  .message-input .wrap button:focus {
    outline: none;
  }

  .fa {
    font-size: 1.5em !important;
  }

  small {
    font-size: 60% !important;
  }

  #frame #sidepanel #profile .wrap #expanded button {
    float: left;
    border: none;
    width: 50%;
    background: #445e92;
    color: #f6f8fa;
    cursor: pointer;
    font-size: 0.85em;
    font-family: "proxima-nova", "Source Sans Pro", sans-serif;
    margin-bottom: 6px;
    border-radius: 3px;
    padding: 7px;
    width: calc(100% - 43px);
  }
}