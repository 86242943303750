.calendar-outer {
  display: flex;
  .calendar-container {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    font-family: "Arial", sans-serif;
    background-color: #f9fafc;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
    color: #333;
    flex-grow: 1;
    .calender-header-outer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: #f0f9ff;
      padding: 1rem;
    }

    .calendar-header {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 20px 0;
      background: #e0f2ff;

      button {
        background-color: transparent; /* Default background for inactive buttons */
        border: 1px solid transparent;
        border-radius: 6px;
        padding: 5px 10px;
        min-width: 66px;
        font-size: 1rem;
        color: #333;
        cursor: pointer;
        margin: 5px;
        transition: background-color 0.2s ease, border-color 0.2s ease;

        &:hover {
          background-color: rgb(242, 242, 242);
        }

        &.active {
          background-color: white; /* White background for the active button */

          font-weight: bold; /* Make the active button's text bold */
        }

        &:focus {
          outline: none;
        }
      }
    }
    .calendar-navigation {
      display: flex;
      align-items: center;

      button {
        background-color: #fff; /* White background for buttons */
        border: 1px solid #d1d1d1; /* Light grey border */
        border-radius: 6px;
        padding: 8px 12px;
        font-size: 1rem;
        color: #333;
        cursor: pointer;
        margin: 0 5px;
        transition: background-color 0.2s ease, border-color 0.2s ease;

        &:hover {
          background-color: #e6f0fc; /* Light blue background on hover */
          border-color: #b0c4de; /* Darker border on hover */
        }

        &:focus {
          outline: none;
          border-color: #5b9bd5; /* Blue border when button is focused */
        }
      }

      .today-btn {
        background-color: #5b9bd5; /* Blue for the 'Today' button */
        color: white;
        border: none;
        padding: 8px 14px;

        &:hover {
          background-color: #4a90e2; /* Darker blue on hover */
        }
      }
    }

    .calendar-body {
      flex-grow: 1;
      background-color: white;
      padding: 1rem;
      border-radius: 8px;
    }

    @media (max-width: 768px) {
      .calendar-header {
        flex-direction: column;
        align-items: center;

        button {
          width: 100%;
          margin-bottom: 0.5rem;
        }
      }

      .filter {
        flex-direction: column;
        gap: 0.5rem;
      }

      .week-view {
        .week-days {
          grid-template-columns: repeat(2, 1fr);
        }
      }

      .month-view {
        .month-grid {
          grid-template-columns: repeat(2, 1fr);
        }
      }
    }

    @media (max-width: 480px) {
      .calendar-header {
        button {
          padding: 0.5rem;
          font-size: 0.85rem;
        }
      }

      .day-view,
      .week-view,
      .month-view {
        padding: 0.5rem;
      }

      .event-item {
        padding: 0.5rem;
        font-size: 0.75rem;
      }

      .week-view,
      .month-view {
        .day-column,
        .day-cell {
          padding: 0.5rem;
        }

        h3 {
          font-size: 0.85rem;
        }
      }
    }
  }
  .right-sidebar {
    flex: 1;
    background-color: white;
    padding: 1rem;

    .calendar-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 1.2rem;
      margin-bottom: 1rem;

      .icon {
        cursor: pointer;
        font-size: 1.2rem;
        color: #333;
      }
    }

    /* Weekday names */
    .calendar-weekdays {
      display: grid;
      grid-template-columns: repeat(7, 1fr);
      text-align: center;
      font-weight: bold;
      margin-bottom: 0.5rem;

      .calendar-weekday {
        color: #a1a1a1;
        padding: 0.5rem 0;
      }
    }

    .calendar-grid {
      display: grid;
      grid-template-columns: repeat(7, 1fr);
      gap: 0.5rem;

      .calendar-day {
        background-color: #fff;
        border-radius: 8px;
        padding: 0.5rem 0.7rem;

        border-radius: 50%;
        text-align: center;
        cursor: pointer;
        transition: background-color 0.3s ease;

        &:hover {
          background-color: #e0e7ff;
        }

        &.selected {
          background-color: #32a0ef;
          color: white !important;
        }

        /* Out-of-month days in gray */
        &.out-of-month {
          color: #b0b0b0;
        }

        /* Weekends in red */
        &.weekend {
          color: #f44336;
        }
      }
    }

    .filter-section {
      margin-top: 1.5rem;
      border-top: 1px solid #eaeaea;
      padding-top: 1.5rem;

      h4 {
        font-size: 1rem;
        margin-bottom: 0.5rem;
        font-weight: bold;
      }

      label {
        display: block;
        margin-bottom: 0.5rem;
        cursor: pointer;

        input {
          margin-right: 0.5rem;
        }
      }
    }
  }

  @media (max-width: 768px) {
    .right-sidebar {
      border-left: none;
      border-top: 1px solid #e0e0e0;
    }
  }
}

@media screen and (min-width: 320px) and (max-width: 768px) {
  .calendar-outer {
    flex-direction: column;
    .calendar-container {
      .calendar-header-outer {
        flex-direction: column;
      }
    }
    .right-sidebar{
      min-width: 100%;
      margin: auto;
      .calendar-grid{

      }
      .calendar-day{
        padding: 0px!important;
      }
    }
  }
}
