.layout-inner-account {
    height: 100%;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;

    .accounts-heading {
        font-size: 25px;
        font-weight: bold;
    }

    .accounts-container-outer {
        -ms-overflow-style: none;
        scrollbar-width: none;
        margin: 15px;
        flex-grow: 1;
        padding: 20px;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        background: white;
        overflow: scroll;
        display: flex;
        flex-direction: column;


        

        .accounts-tabs {

            .tab-title {
                display: flex;
                align-items: center;
                font-size: 16px;
                font-weight: 500;

                .tab-icon {
                    width: 20px;
                    height: 20px;
                    margin-right: 8px;
                    border-radius: 50%; // Optional: make the images circular
                    object-fit: cover;
                }
            }

            .nav-link {
                border: none;
                border-bottom: solid 1px #d3d3d3;
                color: rgb(98, 96, 96);
                font-weight: bold;
                font-size: 20px;
            }

            .active {
                font-weight: bold;

                color: #4c85e4;
            }
        }

        .tab-content {
            flex-grow: 1;
            overflow: scroll;
            -ms-overflow-style: none;
            scrollbar-width: none;

            .tab-pane {
                margin: 20px 5%;
            }

            .contact-info-outer {
                padding: 30px;
                margin-bottom: 16px;

                .heading-container {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-bottom: 16px;

                    .heading {
                        font-size: 20px;
                        font-weight: 600;
                        margin-bottom: 12px;
                        color: #333333;
                    }

                    .edit-button-container {
                        .edit-button {
                            padding: 6px 12px;
                            border: 1px solid #007bff;
                            border-radius: 4px;
                            background-color: #007bff;
                            color: #ffffff;
                            cursor: pointer;
                            font-size: 14px;
                        }
                    }
                }

                .contact-info-container {
                    display: flex;
                    // flex-direction: column;
                    justify-content: space-around;
                    border: 1px solid #e0e0e0;
                    border-radius: 8px;
                    background: #f6fbff;


                    .contact-info-item {
                        display: flex;
                        justify-content: space-between;
                        // padding: 8px 0;
                        padding: 11px 0px;
                        flex-direction: column;

                        .label {

                            margin-bottom: 10px;
                            color: #333;
                        }

                        .value {
                            font-weight: 500;
                            color: #333333;
                            font-size: 14px;
                        }
                    }
                }

                .edit-button-outer {
                    margin-top: 12px;
                    padding: 8px 16px;
                    border: none;
                    border-radius: 4px;
                    color: white;
                    cursor: pointer;

                    .button-edit {
                        background: none;
                        width: 80px;
                    }
                }
            }

        }

                .main-outer {
                    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
                    background: white;
                    border-radius: 10px;
                
                    .user-image-outer {
                        // width: 85%;
                        display: flex;
                        background: #EBF5FD;


                        .user-image {
                            width: 57%;
                            // margin-bottom: -73px;
                            margin: 0px auto -73px auto;
                            border-radius: 50%;
                            // box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
                        }
                    }

                    .details-outer {
                        // width: 100%;
                        padding: 22px;
                        // height: 90vh;

                        .label-input-group {
                            margin-top: 17px;
                            display: block;


                            .label {
                                color: #808080;
                                font-size: 14px;
                                font-weight: 500;
                            }

                            input {
                                width: 70%;
                                font-size: 15px;
                                border: none;
                                border-bottom: 1.5px solid #cecece;
                                border-radius: 2px;
                                outline: none !important;
                            }

                            input:disabled {
                                border: none;
                                color: #333333;
                                font-weight: 500;
                                font-size: 14px;
                            }
                        }
                    }

                }
            }

            .organisation-info-outer {
                padding: 30px;
                margin-bottom: 10px;

                .heading-container {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-bottom: 16px;

                    .heading {
                        font-size: 20px;
                        font-weight: 600;
                        margin-bottom: 12px;
                        color: #333333;

                        .edit-button-outer {
                            margin-left: 16px;
                            margin-top: 12px;
                            padding: 8px 16px;
                            border: none;
                            border-radius: 4px;
                            // color: white;
                            cursor: pointer;

                            .button-edit {
                                background: none;
                            }

                        }
                    }

                }

                .edit-button {
                    background: #e0e7ff;
                    border: none;
                    color: #3b5bdb;
                    border-radius: 4px;
                    padding: 8px 12px;
                    margin-top: 16px;
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    gap: 4px;

                    i {
                        font-size: 14px;
                    }

                    // &:hover {
                    //     background: #d0d7ef;
                    // }
                }
            }

            .organisation-info-container {

                .organisation-info-box {
                    border: 2px solid #ddd;
                    border-radius: 8px;
                    padding: 20px;
                    background: #f6fbff;

                    .row {
                        display: flex;
                        justify-content: space-between;

                        .col-left,
                        .col-right {
                            flex: 1;
                            padding: 10px;
                        }

                        .col-left {
                            border-right: 2px solid #ddd;
                            margin-right: 10px;

                            .designation-section {
                                margin-bottom: 20px;

                                .designation-heading {
                                    font-size: 14px;
                                    margin-bottom: 6px;
                                    color: #808080;
                                    font-weight: 500;


                                }

                                .designation-title {
                                    font-weight: 500;
                                    color: #333333;
                                    font-size: 16px;
                                }
                            }

                            .experience-section {
                                .experience-heading {
                                    font-size: 14px;
                                    color: #808080;
                                    font-weight: 500;
                                    margin-bottom: 6px;
                                }

                                .experience-details {
                                    .experience-item {
                                        display: flex;
                                        align-items: start;
                                        margin-bottom: 15px;
                                        position: relative;

                                        .bullet-circle {
                                            width: 8px;
                                            height: 8px;
                                            background: #007BFF;
                                            border-radius: 50%;
                                            margin-right: 10px;
                                            margin-top: 5px;
                                        }

                                        .experience-info {
                                            .position {
                                                font-weight: 500;
                                                color: #333333;
                                                font-size: 15px;
                                            }

                                            div {
                                                font-weight: 500;
                                                color: #808080;
                                                font-size: 14px;
                                            }
                                        }
                                        .line-between {
                                            position: absolute;
                                            width: 1px;
                                            height: 115%;
                                            background: blue;
                                            top: 11px;
                                            left: 3px;
                                        }
                                    }

                                }
                            }

                        }




                        .col-right {
                            h3 {
                                font-size: 14px;
                                font-weight: 500;
                                color: #808080;
                            }

                            .awards-list,
                            .conference-list {
                                list-style: none;
                                color: #333333;
                                font-size: 15px;
                                font-weight: 500;

                                li {
                                    position: relative;
                                    padding-left: 20px;
                                    margin-bottom: 5px;
                                    font-size: 0.95em;


                                    &:before {
                                        content: '';
                                        position: absolute;
                                        left: 0;
                                        top: 6px;
                                        height: 8px;
                                        width: 8px;
                                        background: #007BFF;
                                        /* Blue circle for bullets */
                                        border-radius: 50%;
                                    }
                                }
                            }
                        }
                    }
                }
            }





            .action-btns-outer {
                display: flex;
                margin: 20px auto;
                justify-content: end;

                .action-btn {
                    border: none;
                    width: 100px;
                    font-weight: bold;
                    padding: 5px;
                    border: 2px solid #4d4d4d;
                    ;
                }

                .accept-btn {
                    background: #4d4d4d;
                    ;
                    color: white;
                    margin-left: 10px;
                }

                .cancel-btn {
                    background: white;
                    color: #4d4d4d;

                }
            }

            .documents-outer {
                .add-new-document-outer {
                    text-align: right;

                    .add-new-document {
                        background: #fefefe;
                        border: 1px solid #ecebf0;
                        border-radius: 3px;
                        color: #0f1f47;
                        font-size: 18px;
                        font-weight: 500;
                    }
                }

                .category-list-outer {
                    width: 70%;
                    margin: auto;

                    .category-heading {
                        margin: 20px auto;
                        color: grey;
                        font-weight: bold;
                    }

                    .category-item {
                        padding: 10px 0px;
                        border-bottom: 1px solid #d8d8d8;

                        .verified-outer {
                            text-align: right;
                            font-size: 24px;

                            .verified-badge {
                                color: #cacaca;
                            }
                        }

                        .delete-document-outer {
                            text-align: right;

                            .delete-document-btn {
                                color: red;
                                background-color: transparent;
                                border: none;
                                padding: 0px;
                                font-size: 21px;
                            }
                        }
                    }
                }
            }

            .settings-outer {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: 20px;


                .delete-account-box {
                    width: 100%;
                    border-radius: 10px;
                    padding: 20px;
                    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
                    border: 1px solid #FF3B3040;

                    .delete-account-heading-box {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        margin-bottom: 16px;
                        background-color: #FF3B300F;
                        border: none;
                        border-radius: 8px;
                        padding: 10px;


                        .delete-account-heading {
                            color: #ff0000; // Red color
                            font-size: 20px;
                            font-weight: bold;
                            margin-bottom: 10px;
                        }
                    }

                    .delete-account-content {
                        color: #333;
                        font-size: 14px;
                        margin-bottom: 15px;

                        .delete-account-warning {}

                        .delete-account-list {
                            list-style-type: disc;
                            margin-left: 20px;
                            color: #000; // Black color for the list items

                            li {
                                margin-bottom: 5px;
                            }
                        }
                    }

                    .delete-account-btn {
                        background-color: #ff4d4d; // Light red for the button
                        color: #fff;
                        border: none;
                        border-radius: 5px;
                        padding: 10px 20px;
                        cursor: pointer;
                        transition: background-color 0.3s;

                        // &:hover {
                        //     background-color: #ff1a1a; // Darker red on hover
                        // }
                    }
                }
    }
}

@media screen and (min-width: 320px) and (max-width: 360px) {
    .layout-inner-account {
        .accounts-container-outer {
            padding: 10px;
            margin: 8px;

            .accounts-heading {
                font-size: 18px;
            }

            .accounts-tabs {
                .tab-title {
                    font-size: 14px;

                    .tab-icon {
                        width: 16px;
                        height: 16px;
                    }
                }



                .nav-link {
                    font-size: 16px;
                }
            }

            .tab-content {
                .tab-pane {
                    margin: 15px 4%;
                }

                .contact-info-outer {
                    padding: 20px;

                    .heading-container {
                        .heading {
                            font-size: 18px;
                        }

                        .edit-button {
                            font-size: 12px;
                        }
                    }

                    .contact-info-container {
                        flex-direction: column;

                        .contact-info-item {
                            padding: 8px 10px;

                            .label {
                                font-size: 12px;
                            }

                            .value {
                                font-size: 12px;
                            }
                        }
                    }
                }

                .organisation-info-outer {
                    padding: 20px;

                    .heading-container {
                        .heading {
                            font-size: 18px;
                        }
                    }

                    .edit-button {
                        font-size: 12px;
                    }
                }

                .organisation-info-container {
                    .organisation-info-box {
                        padding: 15px;

                        .row {

                            .col-left,
                            .col-right {
                                padding: 8px;
                            }

                            .col-left {
                                .designation-title {
                                    font-size: 14px;
                                }

                                .experience-info {
                                    .position {
                                        font-size: 14px;
                                    }
                                }
                            }

                            .col-right {
                                h3 {
                                    font-size: 12px;
                                }

                                .awards-list,
                                .conference-list {
                                    font-size: 13px;
                                }
                            }
                        }
                    }
                }

                .settings-outer {
                    flex-direction: column;
                }

                .delete-account-box {
                    padding: 15px;

                    .delete-account-heading {
                        font-size: 16px;
                    }

                    .delete-account-content {
                        font-size: 12px;
                    }

                    .delete-account-btn {
                        padding: 8px 15px;
                        font-size: 14px;
                    }
                }
            }

            .row {
                .col-4 {
                    .main-outer {
                        .details-outer {
                            padding: 15px;

                            .label-input-group {
                                margin-top: 10px;

                                .label {
                                    font-size: 12px;
                                }

                                input {
                                    font-size: 13px;
                                    width: 90%;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}


@media screen and (min-width: 361px) and (max-width: 480px) {
    .layout-inner-account {
        .accounts-container-outer {
            padding: 15px;
            margin: 10px;

            .accounts-heading {
                font-size: 20px;
            }

            .accounts-tabs {
                .tab-title {
                    font-size: 15px;

                    .tab-icon {
                        width: 18px;
                        height: 18px;
                    }
                }

                .nav-link {
                    font-size: 18px;
                }
            }

            .tab-content {
                .tab-pane {
                    margin: 15px 3%;
                }

                .contact-info-outer {
                    padding: 25px;

                    .heading-container {
                        .heading {
                            font-size: 19px;
                        }

                        .edit-button {
                            font-size: 13px;
                        }
                    }

                    .contact-info-container {
                        flex-direction: column;

                        .contact-info-item {
                            padding: 10px 15px;

                            .label {
                                font-size: 13px;
                            }

                            .value {
                                font-size: 13px;
                            }
                        }
                    }
                }

                .organisation-info-outer {
                    padding: 25px;

                    .heading-container {
                        .heading {
                            font-size: 19px;
                        }
                    }

                    .edit-button {
                        font-size: 13px;
                    }
                }

                .organisation-info-container {
                    .organisation-info-box {
                        padding: 18px;
                        

                        .row {

                            display: block;
                            .col-left,
                            .col-right {
                                padding: 10px;
                                border-right: none;
                            }

                            .col-left {
                                .designation-title {
                                    font-size: 15px;
                                }

                                .experience-info {
                                    .position {
                                        font-size: 15px;
                                    }
                                }
                            }

                            .col-right {
                                h3 {
                                    font-size: 13px;
                                }

                                .awards-list,
                                .conference-list {
                                    font-size: 14px;
                                }
                            }
                        }
                    }
                }

                .settings-outer {
                    flex-direction: column;
                }

                .delete-account-box {
                    padding: 18px;

                    .delete-account-heading {
                        font-size: 19px;
                    }

                    .delete-account-content {
                        font-size: 13px;
                    }

                    .delete-account-btn {
                        padding: 9px 18px;
                        font-size: 15px;
                    }
                }
            }

            .row {
                .col-4 {
                    .main-outer {
                        .details-outer {
                            padding: 18px;

                            .label-input-group {
                                margin-top: 12px;

                                .label {
                                    font-size: 13px;
                                }

                                input {
                                    font-size: 14px;
                                    width: 85%;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}



@media screen and (min-width: 481px) and (max-width: 575.98px) {
    .layout-inner-account {
      height: 100%;
      display: flex;
      width: 100%;
      flex-direction: column;
      justify-content: center;
  
      .accounts-container-outer {
        margin: 15px;
        padding: 25px;
        flex-grow: 1;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px;
        background: white;
        overflow: auto;
  
        .accounts-heading {
          font-size: 26px;
        }
  
        .accounts-tabs {
          .tab-title {
            font-size: 16px;
  
            .tab-icon {
              width: 20px;
              height: 20px;
            }
          }
  
          .nav-link {
            font-size: 20px;
          }
  
          .active {
            font-size: 20px;
          }
        }
  
        .tab-content {
          .tab-pane {
            margin: 20px 5%;
          }
  
          .contact-info-outer {
            padding: 30px;
  
            .heading-container {
              margin-bottom: 15px;
  
              .heading {
                font-size: 20px;
              }
  
              .edit-button {
                font-size: 14px;
                padding: 6px 12px;
              }
            }
  
            .contact-info-container {
              padding: 20px;
            }
          }
  
          .row {
            .col-4 {
              .main-outer {
                .user-image-outer {
                  width: 90%;
  
                  .user-image {
                    width: 60%;
                  }
                }
  
                .details-outer {
                  padding: 25px;
                  height: auto;
  
                  .label-input-group {
                    margin-top: 15px;
  
                    .label {
                      font-size: 14px;
                    }
  
                    input {
                      width: 85%;
                    }
                  }
                }
              }
            }
          }
  
          .organisation-info-outer {
            padding: 30px;
  
            .heading {
              font-size: 20px;
            }
          }
  
          .organisation-info-container {
            .organisation-info-box {
              padding: 20px;
  
              .row {
                .col-left,
                .col-right {
                  padding: 10px;
                  border-right: none;
                }
  
                .col-left {
                  .designation-heading,
                  .experience-heading {
                    font-size: 14px;
                  }
  
                  .designation-title,
                  .experience-details .position {
                    font-size: 16px;
                  }
                }
  
                .col-right {
                  h3 {
                    font-size: 15px;
                  }
  
                  .awards-list,
                  .conference-list {
                    font-size: 16px;
                  }
                }
              }
            }
          }
  
          .action-btns-outer {
            .action-btn {
              width: 100px;
              font-size: 14px;
            }
  
            .accept-btn,
            .cancel-btn {
              margin-left: 10px;
            }
          }
  
          .delete-account-box {
            padding: 20px;
  
            .delete-account-heading-box {
              padding: 10px;
  
              .delete-account-heading {
                font-size: 20px;
              }
            }
  
            .delete-account-content {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
@media screen and (min-width: 575.99px) and (max-width: 787px) {
.layout-inner-account .organisation-info-container .organisation-info-box .row .col-left {
    border-right: none;
}
}
  



@media screen and (min-width: 768px) and (max-width: 991.98px) {
    .layout-inner-account {
        height: 100%;
        display: flex;
        width: 100%;
        flex-direction: column;
        justify-content: center;

        .accounts-container-outer {
            margin: 15px;
            padding: 25px;
            flex-grow: 1;
            box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px;
            background: white;
            overflow: auto;

            .accounts-heading {
                font-size: 26px;
            }

            .accounts-tabs {
                .tab-title {
                    font-size: 16px;

                    .tab-icon {
                        width: 20px;
                        height: 20px;
                    }
                }

                .nav-link {
                    font-size: 20px;
                }

                .active {
                    font-size: 20px;
                }
            }

            .tab-content {
                .tab-pane {
                    margin: 20px 5%;
                }

                .contact-info-outer {
                    padding: 30px;

                    .heading-container {
                        margin-bottom: 15px;

                        .heading {
                            font-size: 20px;
                        }

                        .edit-button {
                            font-size: 14px;
                            padding: 6px 12px;
                        }
                    }

                    .contact-info-container {
                        padding: 20px;
                    }
                }

                .row {
                    .col-4 {
                        .main-outer {
                            .user-image-outer {
                                width: 90%;

                                .user-image {
                                    width: 60%;
                                }
                            }

                            .details-outer {
                                padding: 25px;
                                height: auto;

                                .label-input-group {
                                    margin-top: 15px;

                                    .label {
                                        font-size: 14px;
                                    }

                                    input {
                                        width: 85%;
                                    }
                                }
                            }
                        }
                    }
                }

                .organisation-info-outer {
                    padding: 30px;

                    .heading {
                        font-size: 20px;
                    }
                }

                .organisation-info-container {
                    .organisation-info-box {
                        padding: 20px;

                        .row {

                            .col-left,
                            .col-right {
                                padding: 10px;
                            }

                            .col-left {

                                .designation-heading,
                                .experience-heading {
                                    font-size: 14px;
                                }

                                .designation-title,
                                .experience-details .position {
                                    font-size: 16px;
                                }
                            }

                            .col-right {
                                h3 {
                                    font-size: 15px;
                                }

                                .awards-list,
                                .conference-list {
                                    font-size: 16px;
                                }
                            }
                        }
                    }
                }

                .action-btns-outer {
                    .action-btn {
                        width: 100px;
                        font-size: 14px;
                    }

                    .accept-btn,
                    .cancel-btn {
                        margin-left: 10px;
                    }
                }

                .delete-account-box {
                    padding: 20px;

                    .delete-account-heading-box {
                        padding: 10px;

                        .delete-account-heading {
                            font-size: 20px;
                        }
                    }

                    .delete-account-content {
                        font-size: 14px;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 992px) and (max-width: 1200px) {
    .layout-inner-account {
        height: 100%;
        display: flex;
        width: 100%;
        flex-direction: column;
        justify-content: center;

        .accounts-container-outer {
            margin: 20px;
            padding: 30px;
            flex-grow: 1;
            box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 12px;
            background: white;
            overflow: auto;

            .accounts-heading {
                font-size: 28px;
                margin-bottom: 20px;
            }

            .accounts-tabs {
                display: flex;
                justify-content: space-between;

                .tab-title {
                    font-size: 18px;

                    .tab-icon {
                        width: 24px;
                        height: 24px;
                    }
                }

                .nav-link {
                    font-size: 22px;
                }

                .active {
                    font-weight: bold;
                }
            }

            .tab-content {
                .tab-pane {
                    margin: 25px 10%;

                    .contact-info-outer {
                        padding: 40px;

                        .heading-container {
                            margin-bottom: 20px;

                            .heading {
                                font-size: 22px;
                            }

                            .edit-button {
                                font-size: 16px;
                                padding: 8px 14px;
                            }
                        }

                        .contact-info-container {
                            padding: 25px;
                        }
                    }

                    .row {
                        .col-4 {
                            .main-outer {
                                .user-image-outer {
                                    width: 90%;

                                    .user-image {
                                        width: 65%;
                                    }
                                }

                                .details-outer {
                                    padding: 30px;
                                    height: auto;

                                    .label-input-group {
                                        margin-top: 20px;

                                        .label {
                                            font-size: 16px;
                                        }

                                        input {
                                            width: 90%;
                                            padding: 8px;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .organisation-info-outer {
                        padding: 40px;

                        .heading {
                            font-size: 22px;
                        }
                    }

                    .organisation-info-container {
                        .organisation-info-box {
                            padding: 25px;

                            .row {

                                .col-left,
                                .col-right {
                                    padding: 15px;
                                }

                                .col-left {

                                    .designation-heading,
                                    .experience-heading {
                                        font-size: 14px;
                                    }

                                    .designation-title,
                                    .experience-details .position {
                                        font-size: 18px;
                                    }
                                }

                                .col-right {
                                    h3 {
                                        font-size: 16px;
                                    }

                                    .awards-list,
                                    .conference-list {
                                        font-size: 18px;
                                    }
                                }
                            }
                        }
                    }

                    .action-btns-outer {
                        .action-btn {
                            width: 120px;
                            font-size: 16px;
                            padding: 10px;
                        }

                        .accept-btn,
                        .cancel-btn {
                            margin-left: 15px;
                        }
                    }

                    .delete-account-box {
                        padding: 25px;

                        .delete-account-heading-box {
                            padding: 15px;

                            .delete-account-heading {
                                font-size: 22px;
                            }
                        }

                        .delete-account-content {
                            font-size: 16px;
                        }
                    }
                }
            }
        }
    }
}
