.failed-upload-popup-content {
    width: 70% !important;

    .failed-upload-outer {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        overflow: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;
        .popup-content-inner {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            overflow: scroll;
            -ms-overflow-style: none;
            scrollbar-width: none;
            .failed-popup {
                font-size: 16px;
            }
        }
    }
}