.sitemap-container {
  padding: 5px;
  border: 1px solid rgb(223, 217, 217);
  background: white;
  padding: 20px;
  margin: 20px;
  .tree-table {
      width: 100%;
      margin: 20px;
      thead {
        tr {
          .level {
            width: 452px;
          }
          th {
            background: #ebf6ff;
            padding: 10px;
            
          }
        }
      }
      tbody {
        tr {
          td {
            padding: 15px;
            .globe-icon {
              color: grey;
              margin: auto 10px auto 5px;
            }
            .no-caret::after {
              display: none !important;
            }
            .dropdown-toggle {
              
              padding: 0px;
              background: no-repeat;
              color: black;
              border: navajowhite;
            }
            .dropdown-item {
              span {
                color: #6f9dff;
                margin-right: 10px;
              }
            }
            .dropdown-toggle:hover {
              background: rgb(224, 224, 224);
            }
          }
          .stats-outer-cell {
            display: flex;
            .stats-outer-cell-tab {
              .stats-outer-cell-tab-icon {
                color: #31a0ef;
              }
            }
            &:not(:last-child) {
              .stats-outer-cell-tab {
                margin-right: 10px;
                border-radius: 50px;
                padding: 0px 10px;
                font-size: 18px;
                min-width: 70px;
                box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
                .tab-count {
                  font-size: 13px;
                  margin-left: 5px;
                }
              }
            }
          }
          &:not(:last-child) {
            td {
              border-bottom: 1px solid #f5f5f5;
            }
          }
        }
        tr:hover {
          background: #f5f5f5;
          .dropdown-toggle {
            opacity: 1;
          }
        }
      }
  }
}

.sitemap-header {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  border-bottom: 1px solid #ddd;
}
.sitemap-body{
  padding: 30px;
}

.header-item {
  flex: 1;
 
  margin-left: 10px;
  font-weight: bold;
  color: #a0a0a0; // grey color
  position: relative;
}

.header-item:not(:last-child)::after {
  content: "";
  height: 103%;
  width: 1px;
  background-color: #E4E4E4;
  position: absolute;
  right: 4px;
  top: 15%;
}

.admin-avatar {
  height: 30px;
  width: 30px;
  border-radius: 50%;
}

@media screen and (min-width: 320px) and (max-width: 360px) {
  .sitemap-container {
    padding: 10px;
    margin: 10px;
    .tree-table {
      margin: 10px;
      thead {
        tr {
          .level {
            width: 300px;
          }
        }
      }
      tbody {
        tr {
          td {
            padding: 4px;
          }
        }
      }
    }
  }
}


@media screen and (min-width: 361px) and (max-width: 480px) {
  .sitemap-container {
    padding: 15px;
    margin: 15px;

    .tree-table {
      margin: 15px;

      thead {
        tr {
          .level {
            width: 350px;
          }
        }
      }

      tbody {
        tr {
          td {
            padding: 4px;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 481px) and (max-width: 575.98px) {
  .sitemap-container {
    padding: 20px;
    margin: 20px;

    .tree-table {
      margin: 20px;

      thead {
        tr {
          .level {
            width: 400px;
          }
        }
      }

      tbody {
        tr {
          td {
            padding: 4px;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 576px) and (max-width: 771.2px) {
  .sitemap-container {
    padding: 25px;
    margin: 25px;

    .tree-table {
      margin: 25px;

      thead {
        tr {
          .level {
            width: 450px;
          }
        }
      }

      tbody {
        tr {
          td {
            padding: 4px;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 772px) and (max-width: 1013px) {
  .sitemap-container {
    padding: 28px;
    margin: 28px;

    .tree-table {
      margin: 28px;

      thead {
        tr {
          .level {
            width: 260px;
          }
        }
      }

      tbody {
        tr {
          td {
            padding: 4px;
            .dropdown-toggle {
              opacity: 0;
              padding: 0px;
              background: no-repeat;
              color: black;
              border: navajowhite;
              transition: 0.3s opacity ;
            }
            .dropdown-toggle:hover {
              background: rgb(224, 224, 224);
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1014px) and (max-width: 1200px) {
  .sitemap-container {
    padding: 20px;
    margin: 30px;

    .tree-table {
      margin: 30px;

      thead {
        tr {
          .level {
            width: 260px;
          }
        }
      }

      tbody {
        tr {
          td {
            padding: 18px;
            .dropdown-toggle {
              opacity: 0;
              padding: 0px;
              background: no-repeat;
              color: black;
              border: navajowhite;
              transition: 0.3s opacity ;
            }
            .dropdown-toggle:hover {
              background: rgb(224, 224, 224);
            }
          }
        }
      }
    }
  }
}


@media screen and (min-width: 1201px) {
  .sitemap-container {
    padding: 20px;
    margin: 35px;

    .tree-table {
      margin: 20px;

      thead {
        tr {
          .level {
            width: 300px;
          }
        }
      }

      tbody {
        tr {
          td {
            padding: 20px;
            .dropdown-toggle {
              opacity: 0;
              padding: 0px;
              background: no-repeat;
              color: black;
              border: navajowhite;
              transition: 0.3s opacity ;
            }
            .dropdown-toggle:hover {
              background: rgb(224, 224, 224);
            }
          }
        }
      }
    }
  }
}