.invite-member-form {
  margin: 20px;
  height: auto;
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  h2 {
    text-align: left;
    margin-bottom: 10px;
    color: #121212;
  }
  p {
    text-align: left; // Align the connect text to the left
    margin-bottom: 30px;
    color: #808080;
  }
  h4 {
    display: flex;
    align-items: center;
    margin-top: 20px;
    color: #121212;

    svg {
      margin-right: 8px; // Adjust spacing between icon and text
    }
  }

  .form-group {
    margin-bottom: 20px;
  }

  label {
    font-weight: bold;
    color: #333333;
    font-weight: 500;
  }

  .form-control {
    border-radius: 10px;
    box-shadow: none;
    transition: border-color 0.2s;
    border: 1.5px solid #CCCCCC;
    display: block;
    width: 100%;
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: var(--bs-body-color);
    appearance: none;
    background-color: var(--bs-body-bg);
    background-clip: padding-box;

    &:focus {
      border-color: #007bff;
      box-shadow: none;
    }

    &::placeholder{
      color: #CCCCCC;
    }
  }

  .form-check-input {
    margin-right: 10px;
  }

  .row-info-location {
    border: 1px solid #C6C6C64D;
    padding: 20px;
    border-radius: 15px;
  }

  .btn-primary {
    width: 20%;
    padding: 10px ;
    font-size: 16px;
    border-radius: 10px;
    background-color: #32A0EF!important;
    color: #ffffff !important;
    transition: background-color 0.2s, border-color 0.2s;

    &:hover {
      background-color: #0056b3;
      border-color: #004085;
    }
  }

  .button-group {
    display: flex;
    justify-content: flex-end; // Align buttons to the right
    margin-top: 20px;

    button {
      padding: 10px 15px;
      border: none;
      color: black;
      background-color: #C6C6C6;
      margin-left: 10px; // Space between buttons
      border-radius: 10px;
      font-weight: 600;
    }
  }
}

.form-invite-member{
  width: 70%;
}

.error-text {
  color: red;
  font-size: 13px;
  margin-top: 5px;
}

.input-field-control{
  border : none;
  width: 100%;
}

.input-field-control:focus {
  outline: none;
  border: none; 
}


@media (max-width: 520px) {
  .form-invite-member {
    width: 100%; // Make form take full width on small screens
  }

  .btn-primary {
    width: 100%; // Full width for buttons
  }

  .button-group {
    flex-direction: column; 
      display: flex;
      justify-content: flex-end;
      margin-top: 20px;
      gap: 10px;
    button {
      width: 100%; // Make each button full width
      margin-left: 0;
      margin-top: 10px; // Add space between stacked buttons
    }
  }

  .invite-member-form .row-info-location {
    border: 1px solid rgba(198, 198, 198, 0.3019607843);
    padding: 20px;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
}

  .invite-member-form h2 {
    text-align: center;
    margin-bottom: 10px;
    color: #121212;
    font-size: 23px;
  }

  .invite-member-form p {
    text-align: center;
    margin-bottom: 30px;
    color: #808080;
}

  .invite-member-form .button-group button { padding: 4px 10px;
  gap: 34px;
  border: none;
  color: black;
  width: 50%;
  background-color: #C6C6C6;
  margin-left: 10px;
  border-radius: 10px;
  font-weight: 600;
  text-align: center;
  margin: auto;
  display: flex;
  text-align: center;
  justify-content: center;
}
}