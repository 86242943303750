.add-organisation-outer {
    flex-grow: 1;
    display: flex;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    background: white;
    margin: 40px 5%;
    padding: 10px;
    border-radius: 10px;
    height: 100%;

    .left-side-panel {
        background-color: #f8fafc;
        width: 300px;
        min-height: 100vh;
        padding: 24px;
        box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);

        .logo-section {
            
            align-items: center;
            margin-bottom: 32px;

            .logo {
              
                width: 40%;
                
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 18px;
                font-weight: bold;
                color: #3b82f6;

                img {
                    width: 100%;
                }
            }

            .workspace-welcome {
                font-size: 17px;
                font-weight: 600;
                color: #374151;
                margin-top: 12px;
            }
        }

        .steps {
            list-style-type: none;
            padding: 0;

            .step {
                display: flex;
                align-items: center;
                font-size: 14px;
                color: #4b5563;
                margin-bottom: 35px;
                position: relative;

                .step-icon {
                    background-color: #e0e7ff;
                    color: #3b82f6;
                    width: 24px;
                    height: 24px;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-right: 12px;
                    font-size: 12px;
                    transition: 0.5s background-color;
                }

                .step-label {
                    font-weight: normal;
                    transition: 0.5s font-weight;
                }

                .step-line {
                    position: absolute;
                    width: 2px;
                    height: 0px;
                    background: #32A0EF;
                    left: 11.5px;
                    top: -145%;
                    transition: 0.5s height;
                }

                &.active {
                    .step-icon {
                        background-color: #32A0EF;
                        color: #fff;
                    }

                    .step-label {
                        font-weight: bold;
                        color: black;
                    }

                    .step-line {
                        height: 35px;
                    }
                }
            }
        }
    }

    .nav-tabs {
        display: none;
    }

    .tab-content>.tab-pane {
        flex-grow: 1;
        opacity: 1;
        display: block;
        position: relative;
        overflow: hidden;
        max-width: 0px;
        max-height: 0px;
        opacity: 0;
        transition: max-width 1s, max-height .002s, opacity 1.5s;


    }

    .tab-content {
        height: 100%;
       
        display: flex;
        flex-direction: column;

        .tab {
           
        }


        .tab-heading {
            width: 100%;
            font-size: 21px;
            padding: 26px 0px 0px 0px;
            font-weight: bold;
        }

        .tab-sub-heading {
            width: 100%;
            margin-bottom: 15px;
            text-align: left;
            font-size: 16px;
            color: grey;
        }

        .organisation-location-outer {
            width: 100%;
            padding: 10px;
           
            overflow: scroll;
            -ms-overflow-style: none;
            scrollbar-width: none;
            background: white;
            position: relative;
            filter: blur(0px);
            transition: filter 0.5s;

            .organisation-location-headers-outer {
                padding: 35px;
            }

            .logo-section {
                display: flex;
                align-items: center;
                margin-bottom: 32px;

                .logo {
                    background-color: #dbeafe;
            
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 18px;
                    font-weight: bold;
                    color: #3b82f6;
                }

                .workspace-welcome {
                    font-size: 14px;
                    font-weight: 600;
                    color: #374151;
                }
            }




            .organisation-location-inner {
                padding: 10px;
                display: flex;
                flex-direction: column;

                -ms-overflow-style: none;
                scrollbar-width: none;

                .location-data-outer {
                    position: relative;
                    margin: 10px;
                    overflow: scroll;
                    -ms-overflow-style: none;
                    scrollbar-width: none;
                    flex-grow: 1;

                    .location-data-inner {
                        margin: 20px 0px;
                        position: relative;


                       
                        .location-name {
                            overflow: hidden;
                            font-weight: bold;
                            white-space: nowrap;
                            
                            width: 100%;
                            text-align: center;
                        }

                        .detail-btn {

                            border: none;
                            border-radius: 10px;
                            background: none;
                            color: #0076f4;
                            padding: 0px;
                            margin: auto;
                            font-size: 20px;
                            margin-left: 10px;
                        }

                        .add-location {
                            display: flex;
                            align-items: center;
                            white-space: nowrap;
                            border: none;
                            border-radius: 3px;
                            background-color: #32A0EF;
                            color: #FFFFFF;
                            border: 1px solid #32A0EF;

                            span {
                                display: flex;
                                align-items: center;
                            }
                        }

                        .remove-location {
                            border: none;
                            border-radius: 10px;
                            background: none;
                            color: #fb0c0c;
                            padding: 0px;
                            font-size: 20px;
                            width: 20%;
                         
                        }

                        .location-data-container {
                            background: white;
                            display: flex;
                            justify-content: space-between;
                            padding: 0px 5px;
                            border-radius: 5px;
                            min-height: 30px;
                            align-items: center;
                            box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
                        }

                        .add-branch-outer {
                            text-align: center;
                            margin: 10px 7px;
                            border: 2px solid #81beff;
                            color: #81beff;
                            padding: 5px;
                            border-radius: 5px;
                            background-color: white;
                            width: -webkit-fill-available;
                        }

                        .branches-outer {
                            padding: 5px 0px;
                            margin: 10px auto;
                            border-radius: 5px;
                            box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;

                            .branches-heading {
                                padding: 10px;
                                border-bottom: 1px solid grey;
                                color: #0076f4;
                                font-weight: bold;
                            }

                            .branch {
                                width: -webkit-fill-available;
                                text-align: center;
                                margin: 10px 7px;
                                border: 2px solid #0076f4;
                                color: white;
                                background: #0076f4;
                                padding: 5px;
                                border-radius: 5px;
                            }

                            .add-branch-outer {
                                text-align: center;
                                margin: 10px 7px;
                                border: 2px solid #81beff;
                                color: #81beff;
                                padding: 0px;
                                border-radius: 5px;
                                width: -webkit-fill-available;

                                .add-branch-inner {
                                    width: 100%;
                                    border: none;
                                    padding: 5px;
                                    background: white;
                                    color: #81beff;
                                }
                            }
                        }
                    }

                    .location-data-inner-marker::before {
                        content: "";
                        position: absolute;
                        width: 2px;
                        height: 100%;
                        background-color: black;
                        bottom: 0;
                        left: 50%;
                        transform: translateX(-50%);
                        top: 100%;
                    }

                    .location-data-inner-marker::after {
                        content: "";
                        /* Required for ::after */
                        content: "";
                        position: absolute;
                        width: 11%;
                        height: 2px;
                        background-color: black;
                        left: 94%;
                        top: 50%;
                        transform: translateY(-50%);
                    }

                    .curve-marker {
                        position: relative;
                    }
                    .curve-marker-mobile {
                        display: none;
                    }

                    .curve-marker::before {
                        display: none;
                        content: "";
                        position: absolute;
                        width: 2px;
                        height: 36%;
                        background-color: grey;
                        left: calc(51.2%/1);
                        transform: translateX(-50%);
                    }

                    /* Style for the curved line */
                    .curve-marker::after {

                        content: "";
                        position: absolute;
                        width: 56%;
                        height: 2.5px;
                        background-color: grey;
                        bottom: 0;
                        top: 33.5px;
                        left: 50%;
                    }

                    .curve-marker-continent::after {}

                    .curve-marker-country::before {
                        height: 50%;
                        top: -18%;
                        left: calc(100%/1.344);
                    }

                    .curve-marker-country::after {
                        width: 30%;
                        left: 73.923077%;
                    }

                    .curve-marker-state::before {
                        height: 50%;
                        top: -18%;
                        left: calc(100%/1.197);
                    }

                    .curve-marker-state::after {
                        width: 19.111111%;
                        left: 83.719298%;

                    }

                    .curve-marker-city::before {
                        height: 50%;
                        top: -18%;
                        left: calc(100%/1.149);
                    }

                    .curve-marker-city::after {
                        width: calc(100%/10);
                        left: calc(100%/1.12);
                    }

                    .curve-marker-local {

                        // overflow: hidden;

                    }

                    .curve-marker-local::before {
                        height: 26%;
                        top: -18%;
                        left: calc(100%/1.098);
                    }

                    .curve-marker-local::after {
                        width: 11.692308%;
                        left: 91%;
                        top: 35px;                    
                    }

                    .curve {
                        display: none;
                        position: absolute;
                        width: 25px;
                        height: 35px;
                        background-color: transparent;
                        left: 50.4%;
                        top: -1.4%;
                        border-left: 2px solid grey;
                    }

                    .curve-country {
                        left: calc(100%/1.35);
                    }

                    .curve-state {
                        left: calc(100%/1.2);
                    }

                    .curve-city {
                        left: calc(100%/1.15);
                    }

                    .curve-local {
                        left: calc(100%/1.1);
                        top: 20px;
                    }

                    .side-marker-continent {
                        position: relative;
                    }

                    .side-marker-continent::before {
                        content: "";
                        position: absolute;
                        width: 2px;
                        height: 100%;
                        background-color: grey;
                        left: 9.355%;
                        transform: translateX(-50%);
                    }

                    .side-marker-country {
                        position: relative;
                    }

                    .side-marker-country::before {
                        content: "";
                        position: absolute;
                        width: 2px;
                        height: 100%;
                        background-color: grey;
                        left: 25.35%;
                        transform: translateX(-50%);
                    }

                    .side-marker-state {
                        position: relative;
                    }

                    .side-marker-state::before {
                        content: "";
                        position: absolute;
                        width: 2px;
                        height: 100%;
                        background-color: grey;
                        left: 41.95%;
                        transform: translateX(-50%);
                    }

                    .side-marker-city {
                        position: relative;
                    }

                    .side-marker-city::before {
                        content: "";
                        position: absolute;
                        width: 2px;
                        height: 100%;
                        background-color: grey;
                        left: 8.355%;
                        transform: translateX(-50%);
                    }

                    .side-marker-local {
                        position: relative;
                    }

                    .side-marker-local::before {
                        content: "";
                        position: absolute;
                        width: 2.5px;
                        height: 100%;
                        background-color: grey;
                        left: 75.3%;
                        transform: translateX(-50%);
                    }


                    hr {
                        margin-top: 1rem;
                    }
                }
            }
        }

        .tab3 {
            .organisation-name-outer {

                .organisation-name-label {
                    text-align: left;
                }
            }
        }

        .tab5 {
            .organisation-name-outer {
                .plugins-list {
                    border: 1px solid #32A0EF4D;
                    border-radius: 8px;
                    overflow: hidden;

                    .plugin-outer {
                        display: flex;
                        padding: 10px;
                        width: 100%;
                        border-bottom: 1px solid rgba(50, 160, 239, 0.3019607843);
                      

                        background-color: #ffffff;
                        transition: border-color 0.2s ease-in-out;

                        &:hover {
                            border-color: #d1d5db;
                        }
                    }

                    .plugin-outer:nth-last-child(1) {
                        border-bottom: none;
                    }

                    .plugin-inner {
                        display: flex;
                        align-items: center;
                   
                        justify-content: space-between;
                        width: 100%;
                    }

                    .plugin-image-outer {
                        width: 40px;
                        height: 40px;
                        margin-right: 15px;

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: contain;
                            border-radius: 4px;
                        }
                    }

                    .plugin-details {
                        flex-grow: 1;
                    }

                    .plugin-name {
                        font-size: 16px;
                       
                        color: #111827;
                    }

                    .plugin-description {
                        font-size: 14px;
                        color: #6b7280;
                    }

                    .connect-btn,
                    .connected-btn {
                        border: 1px solid #d1d5db;
                        padding: 5px 15px;
                        font-size: 14px;
                        border-radius: 5px;
                        cursor: pointer;
                        transition: background-color 0.2s ease-in-out;
                    }

                    .connect-btn {
                        background-color: #ffffff;
                        color: #3b82f6;
                        border-color: #c2d9ff;

                        &:hover {
                            background-color: #e0f2fe;
                        }
                    }

                    .connected-btn {
                        background-color: #3b82f6;
                        color: #ffffff;
                        border-color: #3b82f6;

                        &:hover {
                            background-color: #2563eb;
                        }
                    }

                }
            }

            .organisation-name-label {
                text-align: left;
            }

            .download-template-outer {
                display: flex;
                justify-content: space-evenly;
                border: 2px solid #1436f4;
                padding: 20px;
                margin: auto;
                width: fit-content;
                border-radius: 5px;
                margin-bottom: 20px;

                .label {
                    color: #1436f4;
                    font-weight: bold;
                    margin-right: 20px;
                }

                .download-template-btn-outer {
                    .download-template-btn {
                        background: #1436f4;
                        border: 2px solid #1436f4;
                        color: white;
                        border-radius: 5px;
                    }
                }

                .sample-upload-template-container {
                    width: 90%;
                    margin: 10px;
                    opacity: 0;

                    .sample-upload-template {
                        width: 100%;
                    }
                }

                .show-template-icon {
                    cursor: pointer;
                    margin: 10px;
                }

                .show-template-icon:hover+.sample-upload-template-container {
                    opacity: 1;
                    visibility: visible;
                }
            }

          
        }

        .loading-component {
            position: absolute;
            background: rgb(226 226 226 / 28%);
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 1111;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .organisation-name-outer {
            height: 100%;
            overflow: scroll;
            -ms-overflow-style: none;
            scrollbar-width: none;
     
            text-align: left;
            border-radius: 5px;
       
            width: 100%;
          
            position: relative;

            .organisation-name-inner {
                flex-grow: 1;
                filter: blur(0px);
                transition: filter .5s;

            }

         
            input[type="number"] {
                -moz-appearance: textfield;
            }

            input[type="number"]::-webkit-inner-spin-button,
            input[type="number"]::-webkit-outer-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }

            input[type="number"] {
                -webkit-appearance: none;
                margin: 0;
                appearance: none;
            }

            .organisation-name-label-sub {
                margin-top: 0px;
                margin-bottom: 50px;
                color: #808080;
                font-size: 16px;
                font-weight: 400;
            }

            .label {
              
                width: 30%;
                color: grey;
                font-size: 14px;
            }

            .input-field {
           
                width: 100%;
                border: 1px solid #d9d9d9;
                border-radius: 7px;
                padding: 5px;
                padding: 10px;
            }

            .dropdown-item {
                width: 30%;
            }

            .input-field:focus-visible {
                outline: none;
            }

            .error-message {
                color: red;
                font-size: 12px;
            }

            .organisation-logo-outer {

                .line-1 {
                    color: #64748B;
                    margin-bottom: 10px;
                    margin-right: 50px;
                }

                .input-outer {
                    display: flex;
                    flex-direction: row !important;



                    .line-1 {
                        font-size: 16px;
                        font-weight: bold;
                        margin-bottom: 8px;
                    }

                    .organisation-logo {
                        border: 2px solid #bfdbf8;
                        width: 100px;
                        height: 100px;
                        overflow: hidden;
                        margin-bottom: 10px;
                        img{
                            width: 100%;
                            object-fit: cover;
                            height: 100%;
                        }
                    }

                    .upload-section {
                        display: flex;
                        flex-direction: column;
                        object-fit: contain;

                        .file-type,
                        .file-limit {
                            font-size: 12px;
                            color: #666;
                        }

                        .upload-btn-outer {
                            margin-top: 10px;

                            button {
                                background-color: #32A0EF;
                                color: white;
                                padding: 5px 10px;
                                border: none;
                                border-radius: 4px;
                                cursor: pointer;

                                &:hover {
                                    background-color: #2785c8;
                                }
                            }

                            input[type="file"] {
                                display: none;
                            }
                        }
                    }

                    .error-message {
                        color: red;
                        font-size: 12px;
                    }
                }



                .input-outer {
                    display: flex;
                    align-items: center;
                    width: 70%;

                    .input-field {
                        flex-grow: 1;
                        margin-right: 10px;
                    }
                }



                .organisation-logo {
                    width: 90px;
                    height: 90px;
                    border: 1px dashed #b3b3b3;
                    margin: auto 0px;
                    border-radius: 5px;
                    margin-right: 10px;
                    img{
                        width: 100%;
                        object-fit: cover;
                        height: 100%;
                    }
                }

                .upload-section {
                    .upload-btn-outer {
                        .upload-logo-btn-outer {
                            color: #0E6BA8;
                            border: none;
                            padding: 4px 22px;
                            border-radius: 7px;
                            background: #d7ecfa;
                        }
                    }

                    .file-type {
                        margin-top: 10px;
                        color: #64748B;
                    }

                    .file-limit {
                        color: #64748B;
                    }
                }
            }

            .group-pair {
                display: flex;
                margin-bottom: 20px;
                align-items: center;


                .label {
                    flex: 1;
                    font-size: 14px;
                    font-weight: bold;
                    display: flex;
                    align-items: center;
                }

                .input-outer {
                    flex: 2;
                    display: flex;
                    flex-direction: column;

                    .input-field {
                        width: 100%;
                        padding: 8px;
                        border: 1px solid #ddd;
                        border-radius: 4px;
                        font-size: 14px;
                    }

                    .error-message {
                        color: red;
                        font-size: 12px;
                        margin-top: 4px;
                    }
                }

                .dropdown-item {
                    width: 100%;
                    border: 1px solid #ddd;
                    border-radius: 4px;
                }
            }

            .input-outer {
                .input-field {
                    height: 40px;
                }

                .dropdown-item {
                    margin-right: 10px;
                }
            }
        }

        .nav-buttons-outer {
            padding: 20px 25px;
            display: flex;
            justify-content: end;
            background: white;
            margin-bottom: 20px;
            border-radius: 0px 0px 10px 10px;
            position: relative;

            .nav-btn {
                border: none;
                border-radius: 10px;
                background: #0671ff;
                color: white;
                min-width: 77px;
                font-size: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .nav-btn-inactive {
                background: #96bff4;
                pointer-events: none;
            }
        }
    }

    .tab-content>.active {
        opacity: 1;
        max-height: 2000px;
        max-width: 2000px;
       
    }
}


@media screen and (min-width: 320px) and (max-width: 480px) {
    .add-organisation-outer {
        margin: 0px 2%;
        flex-direction: column;
        .left-side-panel {
            width: 100%;
            min-height: auto;
            display: flex;
            flex-direction: column;
            .steps {
                margin: auto;
            }
            .logo-section {
                .logo {
                    margin: auto;
                }
                .workspace-welcome {
                    text-align: center;
                }
            }
        }
        .tab-content {
            .organisation-location-outer {
                .organisation-location-inner {
                    display: none;
                    .location-data-outer {
                        .curve-marker {
                            display: none;
                        }
                        .curve-marker-mobile {
                            display: block;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 481px) and (max-width: 767.98px) {
    .add-organisation-outer {
        margin: 0px 2%;
        flex-direction: column;
        .left-side-panel {
            width: 100%;
            min-height: auto;
            display: flex;
            flex-direction: column;
            .steps {
                margin: auto;
            }
            .logo-section {
                .logo {
                    margin: auto;
                }
                .workspace-welcome {
                    text-align: center;
                }
            }
        }
        .tab-content {
            .organisation-location-outer {
                .organisation-location-inner {
                    display: none;
                    .location-data-outer {
                        .curve-marker {
                            display: none;
                        }
                        .curve-marker-mobile {
                            display: block;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 768px) and (max-width: 991.98px) {
    .add-organisation-outer {
        flex-direction: column;
        .left-side-panel {
            width: 100%;
            min-height: auto;
            display: flex;
            flex-direction: column;
            .steps {
                margin: auto;
            }
            .logo-section {
                .logo {
                    margin: auto;
                }
                .workspace-welcome {
                    text-align: center;
                }
            }
        }
        .tab-content {
            .organisation-location-outer {
                .organisation-location-inner {
                    display: none;
                    .location-data-outer {
                        .curve-marker {
                            display: none;
                        }
                        .curve-marker-mobile {
                            display: block;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 992px) and (max-width: 1200px) {}

@media screen and (min-width: 1201px) and (max-width: 1599px) {}