.outer-landing-navbar {
    background: #fffffff0;
    position: fixed !important;
    right: 0;
    left: 0;
    
    width: 100%;
    z-index: 1;
    border: none;
    padding: 10px 12px !important;

    .landing-navbar {
        font-size: 16px;
       

        .logo-image {
            
        }

        .login-btn {
            text-decoration: none;
            color: white;
            background: blue;
            padding: 5px 14px;
            font-size: 17px;
            border-radius: 25px;
            border: none;
            cursor: pointer;
        }

        .nav-link:visited {
            color: blue;
            font-size: 18px;
            cursor: pointer;

        }

        .user-dropdown-image {
            width: 40px;
            border-radius: 50%;
            border: 1.8px solid rgb(205, 205, 205);
            margin-right: 5px;
        }

        .dropdown-menu {
            right: 0;
            left: auto;
            min-width: auto !important;
            font-size: 16px;
        }
    }
}

.outlet {
   
}

.sidebar-outer {
    margin-top: 80px !important;
    height: calc(100vh - 79px);
    padding: 0px;
    background-repeat: round;
    border-right: 1px solid #f0f0f0;

    .user-info {
        display: flex;
        padding: 10px;
        margin: 10px;
        backdrop-filter: blur(2px);
        background-color: rgb(255 255 255 / 68%);
        
        border-bottom: 1px solid #dadada;

        .user-image {
            width: 65px;
            height: 65px;
            border-radius: 50%;
            border: 4px white solid;
        }

        .user-details {
            display: flex;
            flex-direction: column;
            align-items: start;
            padding-left: 10px;

            .username {
                font-weight: bold;
                font-size: 15px;
                margin: auto auto 0px 0px;
            }

            .role {
                font-size: 13px;
                color: grey;
                margin: 0px auto auto 0px;
            }
        }

    }

    .side-bar-menu-outer {
        padding: 10px;
        margin: 10px;
        backdrop-filter: blur(2px);
        background-color: rgba(255, 255, 255, 0.524);
        border-radius: 8px;

        a {
            text-decoration: none !important;
            cursor: pointer;
        }

        a:hover {
            color: white !important;
        }

        .side-bar-menu-item {
            width: 100%;
            width: 100%;
            padding: 5px 0px;
            font-size: 16px;
            border-radius: 8px;
            margin-bottom: 5px;

            .item-image {
                min-width: 30px;
            }
        }

        .side-bar-menu-item:hover {
            background-color: #00000019;
            ;

        }

        .side-bar-menu-item-active {
            color: white;
            background-color: #00000045;
            ;
        }
    }

}

.main-content {
    
    margin-top: 80px !important;
  
    background-color: #F5F9FC;
}
.main-container-footer {
    .newsletter-section {
      display: flex;
      justify-content: space-between;
      align-items: center;
    
      margin: auto;
      background: rgb(236, 247, 255);
      padding: 20px;
  
      .subscribe-heading-footer {
        display: flex;
        flex-direction: column;
        text-align: left;
        margin-left: 90px;
  
        .subscribe-firstpart-heading-footer {
          font-size: 25px;
          color: rgba(51, 51, 51, 1);
          font-weight: 600;
          font-family: sans-serif;
        }
  
        .subscribe-secondpart-heading-footer {
          font-size: 65px;
          font-family: "Plus Jakarta Sans", sans-serif;
          background: linear-gradient(90deg, #11042B 0%, #32A0EF 100%);
          font-weight: 800;
          background-clip: text;
          -webkit-background-clip: text;
          color: transparent;
          -webkit-text-fill-color: transparent;
        }
      }
  
      .newsletter-form {
        display: flex;
        margin: auto;
        justify-content: center;
  
        input {
          padding: 10px;
          border-radius: 15px;
          border: none;
          width: 355px;
          outline: none;
          margin-right: 10px;
          background: rgba(255, 255, 255, 1);
          border: 1px solid rgba(50, 160, 239, 1);
          color: rgba(171, 171, 171, 1);
          font-weight: 400;
        }
  
        input::placeholder {
          color: rgba(171, 171, 171, 1);
          opacity: 1;
        }
  
        button {
          padding: 10px 20px;
          border-radius: 15px;
          background-color: #4a90e2;
          color: white;
          border: none;
          cursor: pointer;
          font-size: 12px;
  
          &:hover {
            background-color: #357ab9;
          }
        }
      }
    }
  
    .footer-btnup {
      display: flex;
      background: rgba(17, 4, 43, 1);
      align-items: center;
      justify-content: center;
      position: relative;
  
      .footer {
        background: rgba(17, 4, 43, 1);
        color: white;
        padding: 70px;
        text-align: center;
  
        .footer-content {
          display: flex;
          font-family: "Plus Jakarta Sans", sans-serif;
          padding: 20px;
          justify-content: space-between;
          align-items: flex-start;
          text-align: left;
  
          .footer-logo {
            width: 40%;
  
            p {
              color: rgba(255, 255, 255, 0.6);
              margin-top: 10px;
            }
          }
  
          .quick-links,
          .contact-info,
          .social-links {
            width: 16%;
            color: rgba(255, 255, 255, 0.8);
  
            h3 {
                margin-bottom: 15px;
                font-size: 1.5rem;
                color: #ffffff;
            }
  
            .contact-email {
              color: rgba(255, 255, 255, 0.6);
              line-height: 30px;
              text-decoration: none;
            }
  
            .contact-address {
              color: rgba(255, 255, 255, 0.6);
            }
  
            ul {
              list-style: none;
              padding: 0;
  
              li {
                margin-bottom: 10px;
  
                a {
                  color: rgba(255, 255, 255, 0.6);
                  text-decoration: none;
  
                  &:hover {
                    text-decoration: underline;
                  }
                }
              }
            }
  
            .featured-badge {
              white-space: nowrap;
              border: 1px solid var(--primary-antro, rgba(50, 160, 239, 1));
              background: rgba(213, 238, 255, 1);
              color: rgba(50, 160, 239, 1);
              padding: 2px 6px;
              border-radius: 10px;
              font-size: 12px;
              margin-left: 5px;
            }
  
            .icons {
              a {
                color: white;
                font-size: 20px;
                margin: 0 10px;
                text-decoration: none;
  
                &:hover {
                  color: #4a90e2;
                }
              }
            }
          }
          .social-links {
            text-align: left;
            h3 {
                color: #ffffff;
                margin-bottom: 15px;
                font-size: 1.5rem;
            }
          
            .icons {
              display: flex;
              justify-content: center;
              gap: 1rem;
              margin-bottom: 2rem;
          
              a {
                display: inline-block;
                img {
                  width: 32px;
                  height: 32px;
                  transition: transform 0.3s ease;
                }
          
                &:hover img {
                  transform: scale(1.2);
                }
              }
            }
          
            .subscribe {
              font-size: 1.2rem;
              color: #fff;
              margin-bottom: 1rem;
            }
          
            .newsletter-form {
              display: flex;
              justify-content: center;
              align-items: center;
          
              input {
                padding: 0.5rem;
              
                border-radius: 22px 0 0 22px;
               
                background-color: #a0a0a0;
                color: #000;
              }
          
              button {
                padding: 0.5rem 1rem;
                border-radius: 0 22px 22px 0;
                background-color: #008cfa;
                color: white;
                cursor: pointer;
                transition: background-color 0.3s ease;
          
                &:hover {
                  background-color: #005fa8;
                }
              }
            }
          }
          

        }
  
        .footer-bottom {
          background: rgb(17, 4, 43);
          color: rgba(255, 255, 255, 0.6);
          padding: 35px 0px 0px 0px;
          margin-top: 40px;
          align-items: center;
          border-top: 1px solid rgba(255, 255, 255, 0.1);
          font-family: "Plus Jakarta Sans", sans-serif;
          display: flex;
          justify-content: space-between;
          font-size: 18px;
  
          .footer-links {
            a {
              color: rgba(255, 255, 255, 0.6);
              margin: 0 10px;
              text-decoration: none;
              font-family: "Plus Jakarta Sans", sans-serif;
  
              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
      }
  
      .toggle-up-footer {
        padding-top: 29%;
        margin-right: 20px;
        position: absolute;
        bottom: 35px;
        right: 20px;
       
  
        img {
          width: 40px;
        }
      }
    }
  
    // Small devices (max-width: 576px)
    @media (min-width:320px) and (max-width: 576px) {
      .footer-btnup {
        .footer {
          padding: 10px;
          .footer-content {
            .social-links {
              text-align: center;
            }
          }
        }
      }
      .newsletter-section {
        flex-direction: column;
        text-align: center;
        padding: 0px;
        display: block;
  
        .subscribe-heading-footer {
          margin-left: 0;
  
          .subscribe-firstpart-heading-footer {
            font-size: 18px;
          }
  
          .subscribe-secondpart-heading-footer {
            font-size: 35px;
          }
        }
  
        .newsletter-form {
          flex-direction: column;
  
          input {
            width: 80%;
            margin: 10px auto;
         
            margin-bottom: 10px;
          }
  
          button {
            width: min-content;
            margin: auto;
          }
        }
      }
  
      .footer-content {
        flex-direction: column;
        align-items: center;
  
        .footer-logo,
        .quick-links,
        .contact-info,
        .social-links {
          width: 100% !important;
          text-align: center;
          margin-bottom: 20px;
        }
  
        .icons {
          display: flex;
          justify-content: center;
          margin-top: 20px;
        }
      }
  
      .footer-bottom {
        flex-direction: column;
        font-size: 12px;
        gap: 5px;
      }
  
      .toggle-up-footer {
        padding-top: 5%;
  
        img {
          width: 30px;
        }
      }
    }
  
    // Medium devices (min-width: 576px and max-width: 767.98px)
    @media (min-width: 576px) and (max-width: 767.98px) {

      .footer-btnup {
        .footer {
          padding: 10px;
          .footer-content {
            .social-links {
              text-align: center;
            }
          }
        }
      }
  
      .newsletter-section {
        flex-direction: column;
        padding: 0px;
        display: block;
  
        .subscribe-heading-footer {
          .subscribe-firstpart-heading-footer {
            font-size: 20px;
          }
  
          .subscribe-secondpart-heading-footer {
            font-size: 45px;
          }
        }
  
        .newsletter-form {
          input {
            
          }
  
          button {
            
          }
        }
      }
  
      .footer-content {
        flex-direction: column;
        align-items: center;
  
        .footer-logo,
        .quick-links,
        .contact-info,
        .social-links {
          width: 100% !important;
          text-align: center;
          margin-bottom: 20px;
        }
      }
  
      .footer-bottom {
        font-size: 14px;
        text-align: center;
        padding: 20px 0;
        margin-top: 0px !important;
      }
    }
  
    .toggle-up-footer {
      padding-top: 20%;
    }
  }
  
  // Tablet devices (min-width: 768px and max-width: 991.98px)
  @media (min-width: 768px) and (max-width: 991.98px) {
    .main-container-footer {
      padding: 0px;
  
      .newsletter-section {
        .subscribe-heading-footer {
          .subscribe-firstpart-heading-footer {
            font-size: 22px;
          }
  
          .subscribe-secondpart-heading-footer {
            font-size: 50px;
          }
        }
  
        .newsletter-form {
          input {
            width: 70%;
          }
  
          button {
          
          }
        }
      }
  
      .footer-content {
        flex-direction: row;
        align-items: flex-start;
  
        .footer-logo,
        .quick-links,
        .contact-info,
        .social-links {
          width: 100%;
        }
  
        .icons {
          justify-content: flex-start;
        }
      }
  
      .footer-bottom {
        font-size: 16px;
      }
    }
  
    .toggle-up-footer {
      padding-top: 10%;
    }
  }
  
  @media (max-width: 1024px) {
    .main-container-footer {
      .newsletter-section {
        padding: 20px;
      }
    }
  }
@media screen and (max-width: 319px) {}

@media screen and (min-width: 320px) and (max-width: 480px) {
    .home-page-layout-inner {
        .section-1 {
            .section-left {
                .section-1-btns-outer {
                    margin-left: 20px;
                }
            }
        }

        .footer {
            padding: 25px;

            .footer-section-1 {
                .section-1-logo {}
            }

            .footer-section-2 {

                .middle-1 {

                    .text1 {
                        text-align: center;
                    }

                    .btns-outer {
                        .download-btn {
                            .btn-image {}

                            .button-text-outer {
                                .text-1 {}

                                .text-2 {}
                            }
                        }
                    }
                }

                .middle-2 {
                    .link-tag {}
                }
            }

            .footer-section-3 {}

            .footer-section-4 {
                flex-direction: column;
            }

            .social-icon {}

            .footer-links-outer {}

            .copyright-text {}
        }
    }
}

@media screen and (min-width: 481px) and (max-width: 767.98px) {
    .footer {

        .footer-section-1 {
            .section-1-logo {}
        }

        .footer-section-2 {

            .middle-1 {

                .text1 {
                    text-align: center;
                }

                .btns-outer {
                    .download-btn {
                        .btn-image {}

                        .button-text-outer {
                            .text-1 {}

                            .text-2 {}
                        }
                    }
                }
            }

            .middle-2 {
                .link-tag {}
            }
        }

        .footer-section-3 {}

        .footer-section-4 {
            flex-direction: column;
        }

        .social-icon {}

        .footer-links-outer {}

        .copyright-text {}
    }
}


@media screen and (max-width: 575.98px) {
    .footer {

        .footer-section-1 {
            .section-1-logo {}
        }

        .footer-section-2 {
            display: block;

            .middle-1 {

                .text1 {}

                .btns-outer {
                    display: block;

                    .download-btn {
                        margin: auto;
                        margin-left: auto !important;
                        margin-bottom: 10px;

                        .btn-image {}

                        .button-text-outer {
                            .text-1 {}

                            .text-2 {}
                        }
                    }
                }
            }

            .middle-2 {
                display: block;

                .link-tag {}
            }
        }

        .footer-section-3 {}

        .footer-section-4 {}

        .social-icon {}

        .footer-links-outer {}

        .copyright-text {}
    }
}


@media screen and (min-width: 576px) and (max-width: 767.98px) {
    .footer {

        .footer-section-1 {
            .section-1-logo {}
        }

        .footer-section-2 {
            display: block;

            .middle-1 {

                .text1 {}

                .btns-outer {
                    .download-btn {
                        .btn-image {}

                        .button-text-outer {
                            .text-1 {}

                            .text-2 {}
                        }
                    }
                }
            }

            .middle-2 {
                .link-tag {}
            }
        }

        .footer-section-3 {}

        .footer-section-4 {}

        .social-icon {}

        .footer-links-outer {}

        .copyright-text {}
    }
}

@media screen and (min-width: 768px) and (max-width: 991.98px) {
    .footer {

        .footer-section-1 {
            .section-1-logo {}
        }

        .footer-section-2 {
            display: block;

            .middle-1 {

                .text1 {
                    text-align: center;
                }

                .btns-outer {
                    .download-btn {
                        .btn-image {
                            width: 25%;
                        }

                        .button-text-outer {
                            .text-1 {}

                            .text-2 {}
                        }
                    }
                }
            }

            .middle-2 {
                .link-tag {}
            }
        }

        .footer-section-3 {}

        .footer-section-4 {}

        .social-icon {}

        .footer-links-outer {}

        .copyright-text {}
    }
}

@media screen and (min-width: 992px) and (max-width: 1200px) {
    .footer {

        .footer-section-1 {
            .section-1-logo {}
        }

        .footer-section-2 {
            display: block;

            .middle-1 {

                .text1 {}

                .btns-outer {
                    .download-btn {
                        .btn-image {}

                        .button-text-outer {
                            .text-1 {}

                            .text-2 {}
                        }
                    }
                }
            }

            .middle-2 {
                .link-tag {}
            }
        }

        .footer-section-3 {}

        .footer-section-4 {}

        .social-icon {}

        .footer-links-outer {}

        .copyright-text {}
    }
}

@media screen and (min-width: 1201px) and (max-width: 1599px) {
    .footer {

        .footer-section-1 {
            .section-1-logo {}
        }

        .footer-section-2 {

            .middle-1 {
                margin: 40px auto;

                .text1 {}

                .btns-outer {
                    .download-btn {
                        .btn-image {}

                        .button-text-outer {
                            .text-1 {}

                            .text-2 {}
                        }
                    }
                }
            }

            .middle-2 {
                .link-tag {}
            }
        }

        .footer-section-3 {}

        .footer-section-4 {}

        .social-icon {}

        .footer-links-outer {}

        .copyright-text {}
    }
}


@media screen and (min-width: 1600px) {
    /* Styles for extra large devices */
}