.upload-members-popup-content {
    width: 70% !important;

    .upload-members-outer {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        overflow: scroll;
        .popup-content-inner {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            overflow: scroll;
            
        }
        .template-upload-outer {
            padding: 10px;
            .download-template-outer {
                text-align: center;
                padding: 30px 30px 10px 30px;
                .download-template-btn {
                    border: none;
                    background: rgb(14 123 238);
                    border-radius: 5px;
                    padding: 5px 10px;
                    color: white;
                }
                .sample-upload-template-container {
                    width: 90%;
                    margin: 10px;
                    opacity: 0;
                    .sample-upload-template {
                        width: 100%;
                    }
                }
                .show-template-icon {
                    cursor: pointer;
                    margin: 10px;
                }
                .show-template-icon:hover + .sample-upload-template-container {
                    opacity: 1;
                    visibility: visible;
                }
            }
            .update-sheet-label {

            }
            .update-sheet-input {
                padding: 15px;
                input[type="file"]::file-selector-button {
                    border-radius: 4px;
                    padding: 0 16px;
                    height: 40px;
                    cursor: pointer;
                    background-color: white;
                    border: 1px solid rgba(0, 0, 0, 0.16);
                    box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
                    margin-right: 16px;
                    transition: background-color 200ms;
                  }
                  
                  /* file upload button hover state */
                  input[type="file"]::file-selector-button:hover {
                    background-color: #f3f4f6;
                  }
                  
                  /* file upload button active state */
                  input[type="file"]::file-selector-button:active {
                    background-color: #e5e7eb;
                  }
            }
            .functional-btns {
                text-align: right;
                .upload-btn {
                    border-color: rgb(14, 123, 238);
                    color: rgb(14, 123, 238);
                    background: none;
                    border-radius: 5px;
                }
            }
        }
        .manual-add-member-outer {
            padding: 10px;
            .add-member-btn-outer {
                text-align: right;
                .add-member-btn {
                    border: none;
                    background: rgb(14, 123, 238);
                    border-radius: 5px;
                    padding: 5px 10px;
                    color: white;
                    margin: 10px 0px;
                }
            }
            .functional-btns {
                text-align: right;
                .upload-btn {
                    border-color: rgb(14, 123, 238);
                    color: rgb(14, 123, 238);
                    background: none;
                    border-radius: 5px;
                }
            }
            .table {
                font-size: 13px;
                .remove-btn {
                    
                }
            }
        }
        .popup-content-inner-form {
            padding: 10px;
            .form-group {
                display: flex;
                margin: 10px;
                label {
                    width: 40%;
                }
                input {
                    width: 40%;
                }
                .dropdown-item {
                    width: 40%;
                }
            }
            .functional-btns {
                text-align: right;
                .submit-btn {
                    border-color: rgb(14, 123, 238);
                    color: white;
                    background: rgb(14, 123, 238);
                    border-radius: 5px;
                }
                .cancel-btn {
                    border-color: rgb(14, 123, 238);
                    color: rgb(14, 123, 238);
                    background: none;
                    border-radius: 5px;
                    margin-left: 10px;
                }
            }
        }
    }
   
}

// firstName: '',
// lastName: '',
// email: '',
// dob: '',
// phoneNumber: '',
// education: '',
// experience: '',
// gender: '',