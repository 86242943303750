.modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  scroll-behavior: auto;
  .modal-content {
    background: white;
    border-radius: 8px;
    padding: 20px;
    max-width: 600px;
    width: 80%;
    height: 580px;
    margin: auto;
    overflow: scroll;
    scrollbar-width: none;
    .heading{
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 20px;
      color: rgba(51, 51, 51, 1);
    }
    .member-card {
      border: 0.5px solid rgba(50, 160, 239, 0.5);
      border-radius: 10px;
      padding: 20px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 20px;
      margin-bottom: 20px;
      .member-profile {
        display: flex;
        align-items: center;
        gap: 15px;
        .profile-picture {
          width: 100px;
          height: 100px;
          border-radius: 50%;
          object-fit: cover;
        }
        .member-info {
          line-height: 10px;
          h3 {
            font-size: 20px;
            color: rgba(51, 51, 51, 1);
          }
          p {
            font-size: 16px;
            color: rgba(128, 128, 128, 1);
          }
        }
      }
      .member-details {
        background-color: #f0f8ff; /* Light blue background */
        padding: 20px;
        border-radius: 8px;
        width: 100%;
        .row {
          display: grid;
          grid-template-rows: repeat(2, 1fr);
          gap: 10px;
          .pair-key-value {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 10px;
            .key-value {
              display: flex;
              flex-direction: column;
              .key {
                color: rgba(102, 102, 102, 1);
                font-size: 14px;
              }
              .value {
                color: rgba(51, 51, 51, 1);
                font-size: 16px;
              }
            }
          }
        }
      }
    }
    .close-button {
      background-color: #007bff;
      color: white;
      border: none;
      padding: 5px 15px;
      border-radius: 5px;
      cursor: pointer;
      margin-left: auto;
      width: 20%;  
      margin-top: 5px;
    }
  }
}


@media screen and (max-width: 575.98px) {
  .modal-container {
    .modal-content {
      width: 90%;
      .member-card {
        padding: 10px;
        .member-profile {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: auto;
        text-align: center;
        }
        .member-details{
          .row{
            .pair-key-value{
              grid-template-columns: repeat(1, 1fr);
              .key-value{
                .value{
                  font-size: 15px;
                }
              }
            }
          }
        }
      }
      .close-button{
        width: 33%;
      }
    }
  }
}

@media screen and (min-width: 576px) and (max-width: 767.98px) {
  .modal-container {
    .modal-content {
      width: 85%;
      .member-card {
        .member-profile {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: auto;
        text-align: center;
        }
        .member-details{
          .row{
            .pair-key-value{
              grid-template-columns: repeat(1, 1fr);
              .key-value{
                .value{
                  font-size: 18px;
                }
              }
            }
          }
        }
      }
      .close-button{
        width: 33%;
      }
    }
  }
}