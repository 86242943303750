.drag-and-drop {
    width: 100%;
    margin: 0 auto;
    text-align: center;

    &.highlighted {
        background: #e9f8ff;
    }
}

.drop-zone {
    border: 2px dashed #ccc;
    border-radius: 10px;
    padding: 20px;
    cursor: pointer;
    transition: border 0.3s ease;

    &:hover {
        border: 2px dashed #aaa;
    }
}

.file-list {
    margin: 20px;

    .file-list-header {
        color: grey;
        text-align: left;
        margin-bottom: 10px;
    }

    .file-item {
        display: flex;
        background: #eff1f7;
        position: relative;

        .file-image-outer {
            width: 50px;

            .document-image {
                width: 100%;
            }
        }

        .file-details {
            margin-left: 15px;
            text-align: left;

            .filename {
                font-weight: bold;
            }

            .file-size {
                color: grey;
                font-size: 13px;
            }
        }

        .close-btn {
            position: absolute;
            top: 10px;
            right: 10px;
            border: none;
            height: 25px;
            width: 25px;
            padding: 0px;
            font-weight: bold;
            color: grey;
            border-radius: 50%;
        }

        .close-btn:hover {
            background: #e0e0e0;
        }
    }
}

.file-input {
    display: none;
}

.drag-drop-outer {
    display: flex;
    align-items: center;
    justify-content: center;
}

.drag-drop-inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.drag-drop-line1 {
    color: #666666;
    font-weight: 600;
}

.drag-drop-line2 {
    color: #cccccc;
}
