.add-announcement-form {
    background: #fff;
    border-radius: 10px;
    // padding: 40px;
    width: 80%;
    max-width: 1200px;
    margin: 30px auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-family: 'Plus Jakarta Sans', sans-serif;

    h2 {
        font-size: 28px;
        font-weight: 700;
        color: #121212;
        margin-bottom: 8px;
        padding: 40px 40px 0px 40px;
    }

    p {
        padding: 0px 40px;
        font-size: 16px;
        color: #808080;
    }
    form {
        padding: 40px;
    }

    .form-group {
        margin-bottom: 24px;

        .add-images {
            display: flex;
            align-items: center;
            margin-bottom: 10px;

            img {
                margin-right: 12px;
                width: 35px;
                height: 35px;
            }

            label {
                font-size: 18px;
                font-weight: 600;
                color: #444;
            }
        }

        .title, .description, .date, .location, .link {
            font-size: 15px;
            font-weight: 600;
            color: #333333;
            margin-bottom: 10px;
        }

        .input-wrapper, .link-input-wrapper, .date-input-wrapper {
            display: flex;
            align-items: center;
            position: relative;
            margin-bottom: 15px;

            .input-icon {
                position: absolute;
                left: 10px;
                width: 20px;
                height: 20px;
                top: 50%;
                transform: translateY(-50%);
            }

            input, textarea {
                width: 100%;
                padding: 12px 15px 12px 40px;
                border: 1px solid #ccc;
                border-radius: 6px;
                font-size: 14px;
                box-sizing: border-box;
            }

            .description-input {
                height: 120px;
                resize: vertical;
            }

            .link-input {
                padding: 12px 35px;
            }

            .paste-btn {
                position: absolute;
                right: 10px;
                background-color: #EAEAEA;
                color: #808080;
                border: none;
                padding: 6px 12px;
                border-radius: 6px;
                cursor: pointer;
            }
        }

        .input-wrapper-description {
            .input-icon {
                top: 20px;
            }
        }

        .date-time-wrapper {
            display: flex;
            gap: 15px;
            align-items: flex-end;
            justify-content: space-between;
            width: 50%;

            .custom-date-input,
            .time-input-wrapper {
                display: flex;
                flex-direction: column;
                position: relative;
                width: 100%;

                label {
                    margin-bottom: 8px;
                    font-size: 15px;
                    font-weight: 600;
                    color: #333333;
                }

                .date-picker {
                    width: 100%;
                    padding: 10px;
                    height: 40px;
                    border-radius: 6px;
                    border: 1px solid #ccc;
                }
                .time-picker {
                    width: 100%;
                    padding: 10px;
                    height: 40px;
                    border-radius: 6px;
                    border: 1px solid #ccc;
                }

                .calendar-icon {
                    position: absolute;
                    right: 10px;
                    top: 72%;
                    transform: translateY(-50%);
                    width: 20px;
                    height: 20px;
                }

                .clock-icon {
                    position: absolute;
                    right: 10px;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 20px;
                    height: 20px;
                }
            }
        }

        .error {
            color: #d9534f;
            font-size: 12px;
            margin-top: 5px;
        }
    }

    .form-actions {
        display: flex;
        justify-content: flex-end;

        button {
            padding: 10px 20px;
            font-size: 14px;
            border-radius: 6px;
            border: none;
            cursor: pointer;
            transition: background-color 0.2s ease;
        }

        .cancel-btn {
            background-color: #C6C6C6;
            color: #333;
            margin-right: 10px;
        }

        .create-btn {
            background-color: #32A0EF;
            color: white;
        }
        .create-btn:disabled  {
            background-color: #C6C6C6;
        }
    }

    // Media Queries for Responsiveness
    @media (min-width: 992px) {
        .input-wrapper,
        .link-input-wrapper {
            width: 50%;
        }

        .date-time-wrapper {
            flex-direction: row;
            width: 100%;
            .custom-date-input,
            .time-input-wrapper {
                width: 48%;
            }
        }
    }

    @media (max-width: 768px) {
        width: 90%;
        padding: 20px;

        .form-group {
            .date-time-wrapper {
                flex-direction: column;
                align-items: flex-start;
                width: 100%;

                .custom-date-input,
                .time-input-wrapper {
                    width: 100%;
                }
            }

            .input-wrapper,
            .link-input-wrapper {
                width: 100%;
            }
        }

        .form-actions {
            flex-direction: column;
            align-items: flex-end;

            button {
                width: 100%;
                margin-bottom: 10px;
            }

            .cancel-btn {
                margin-right: 0;
            }
        }
    }

    @media (max-width: 480px) {
        .form-group {
            .add-images img {
                width: 20px;
                height: 20px;
            }

            .input-wrapper, .link-input-wrapper {
                input, textarea {
                    padding: 10px 35px;
                    font-size: 13px;
                }

                .description-input {
                    height: 100px;
                }
            }
        }

        .form-actions {
            button {
                padding: 8px 15px;
                font-size: 13px;
            }
        }
    }

    @media (max-width: 360px) {
        .add-announcement-form {
            width: 95%;
            padding: 15px;

            h2 {
                font-size: 22px;
            }

            p {
                font-size: 12px;
            }

            .form-group {
                label {
                    font-size: 14px;
                }

                input, textarea, .date-picker {
                    font-size: 12px;
                }

                .description-input {
                    height: 80px;
                }
            }

            .form-actions {
                button {
                    font-size: 12px;
                    padding: 8px 12px;
                }
            }
        }
    }
}
