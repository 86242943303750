.location-tree {
  font-family: Arial, sans-serif;
  display: none;
  .location-node {
    margin-left: 20px;
    .location-header {
      display: flex;
      align-items: center;
      margin-bottom: 5px;
      .toggle-button {
        background: none;
        border: none;
        cursor: pointer;
        font-size: 20px;
        width: 20px;
        margin-right: 5px;
        text-align: center;
        color: #007bff;
      }
      .spacer {
        display: inline-block;
        width: 20px;
      }
      .location-name {
        font-weight: bold;
        margin-right: 10px;
        // flex-grow: 1;
      }
      .location-actions {
        margin-right: 0px;
        margin-left: auto;
        display: flex;
        align-items: center;

        .add-button {
          background-color: transparent;
          color: #32a0ef;
          white-space: nowrap;
          border: none;
          padding: 5px 10px;
          cursor: pointer;
          border-radius: 4px;
          font-size: 12px;
          margin-left: 5px;
          border: 2px solid #32a0ef;
          width: 150px;
        }
        .delete-button {
          background-color: red;
          color: white;
          border: none;
          padding: 5px 10px;
          cursor: pointer;
          border-radius: 4px;
          font-size: 12px;
          margin-left: 5px;
        }
      }
    }
    .sublocations {
      margin-left: 20px;
      border-left: 1px solid #ccc;
      padding-left: 10px;
    }
    .branches {
      margin-left: 20px;
      .branch {
        margin: 10px 0px 10px 0px;
        border-radius: 5px;
        box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em,
          rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
        padding: 5px;
        .branch-name {
          font-weight: bold;
          white-space: nowrap;
        }
        .branch-details {
          margin-left: 10px;
          color: #555;
        }
      }
    }
    .add-form {
      margin-left: 20px;
      margin-top: 10px;
      background-color: #f9f9f9;
      padding: 10px;
      border-radius: 4px;
      form {
        display: flex;
        flex-direction: column;
        h4 {
          margin-top: 0;
        }
        input {
          margin-bottom: 10px;
          padding: 5px;
          border: 1px solid #ccc;
          border-radius: 4px;
        }
        button {
          padding: 5px 10px;
          border: none;
          border-radius: 4px;
          cursor: pointer;
          font-size: 12px;
        }
        .cancel-button {
          background-color: #dc3545;
          color: white;
          margin-left: 10px;
        }
        button[type="submit"] {
          background-color: #007bff;
          color: white;
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .location-node {
    .location-header {
      // flex-wrap: wrap;
      .location-name {
        flex-basis: 100%;
        margin-bottom: 5px;
      }
      .location-actions {
        margin-left: 0px !important;
      }
    }
    .sublocations {
      margin-left: 0px !important;
    }
  }
}

@media screen and (min-width: 320px) and (max-width: 480px) {
  .location-tree {
    display: block;
  }
}

@media screen and (min-width: 481px) and (max-width: 767.98px) {
  .location-tree {
    display: block;
  }
}

@media screen and (min-width: 768px) and (max-width: 991.98px) {
  .location-tree {
    display: block;
  }
}

@media screen and (min-width: 992px) and (max-width: 1200px) {
}

@media screen and (min-width: 1201px) and (max-width: 1599px) {
}
