.month-view {
  .month-grid {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
   

    .day-cell {
     
      padding: 1rem;
      
      border: 1px solid #f4f4f4;
 
      position: relative;

      h3 {
        font-size: 1rem;
        margin-bottom: 0.5rem;
        color: #333;
        text-align: center;
      }

      .event-item {
     
        padding: 0.5rem 1rem;
        border-radius: 8px;
        margin-bottom: 0.5rem;
        box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
        

        p {
          margin: 0;
          font-size: 0.85rem;
          font-weight: bold;
        }

        span {
          font-size: 0.75rem;
          color: #64748b;
        }
      }
      .event-data-item {
        background-color: #FFE7E7;
      }
      .announcement-data-item {
        background-color: #E7F2FF;
      }
      .meeting-data-item {
        background-color: #EBFFE7;
      }
    }
  }
}
