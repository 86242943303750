.layout-inner {
   
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;

    .organisation-map-outer {
        position: relative;
        flex-grow: 1;
        padding: 20px;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        background: white;
        margin: 10px 10px 20px 10px;

        .show-history-outer {
            text-align: right;

            .show-history-button {
                border: none;
                padding: 2px 10px;
                color: white;
                background: #0076f4;
                border-radius: 15px;
                font-weight: bold;
            }
        }

        .page-heading {
            text-align: center;
        }

        .organisation-location-outer {
            width: 100%;
            padding: 10px;
            .data-outer-heading{
                font-size: 20px;
                font-weight: bold;
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin: 10px auto;
                padding: 10px;
                border-radius: 5px;
            }

            .organisation-location-headers-outer {
                padding: 3px 35px;
            }

            .organisation-location-inner {
                border: 1px solid #dadada;
                border-radius: 10px;
                padding: 10px;

                .location-data-outer {
                    position: relative;
                    margin: 10px;
                    

                    .location-data-inner {
                        margin: 20px 0px;
                        position: relative;

                      
                        .location-name {
                            font-weight: bold;
                            word-wrap: break-word;
                            width: 80%;
                        }
                        .location-func-btns {
                            width: 20%;
                        }

                        .detail-btn {

                            border: none;
                            border-radius: 10px;
                            background: none;
                            color: #67afe4;
                            padding: 0px;
                            margin: auto;
                            font-size: 20px;
                            margin-left: 15px;
                        }

                        .add-location {
                            border: none;
                            border-radius: 5px;
                            background-color: #ecf6ff;
                            border: none;
                            color: #4d9fdb;
                            border: 1px solid #0076f4;
                        }

                        .remove-location {
                            border: none;
                            border-radius: 10px;
                            background: none;
                            color: #fb0c0c;
                            padding: 0px;
                            font-size: 20px;
                            margin-left: 10px;
                        }

                        .location-data-container {
                            display: flex;
                            justify-content: space-between;
                            padding: 0px 5px;
                            border-radius: 5px;
                            min-height: 30px;
                            align-items: center;
                            box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
                        }

                        .add-branch-outer {
                            text-align: center;
                            margin: 10px 7px;
                            border: 2px solid #81beff;
                            color: #81beff;
                            padding: 5px;
                            border-radius: 5px;
                            background-color: white;
                            width: -webkit-fill-available;
                        }

                        .branches-outer {
                            padding: 5px 0px;
                         
                            margin: 10px auto;
                            border-radius: 5px;
                            box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;

                            .branches-heading {
                                padding: 10px;
                                border-bottom: 1px solid rgb(247, 242, 242);
                                color: #0076f4;
                                font-weight: bold;
                            }

                            .branch {
                                width: -webkit-fill-available;
                                text-align: center;
                                margin: 10px 7px;
                                border: 2px solid #0076f4;
                                color: white;
                                background: #0076f4;
                                padding: 5px;
                                border-radius: 5px;
                            }

                            .add-branch-outer {
                                text-align: center;
                                margin: 10px 7px;
                                border: 2px solid #81beff;
                                color: #81beff;
                                padding: 0px;
                                border-radius: 5px;
                                width: -webkit-fill-available;

                                .add-branch-inner {
                                    width: 100%;
                                    border: none;
                                    padding: 5px;
                                    background: white;
                                    color: #329fef;
                                }
                            }
                        }
                    }

                    .location-data-inner-marker::before {
                        content: "";
                        position: absolute;
                        width: 2px;
                        height: 100%;
                        background-color: #81beff;
                        bottom: 0;
                        left: 50%;
                        transform: translateX(-50%);
                        top: 100%;
                    }

                    .location-data-inner-marker::after {
                        content: "";
                        /* Required for ::after */
                        content: "";
                        position: absolute;
                        width: 11%;
                        height: 2px;
                        background-color: #81beff;
                        left: 94%;
                        top: 50%;
                        transform: translateY(-50%);
                    }

                    .curve-marker {
                        position: relative;
                    }

                    .curve-marker::before {
                        content: "";
                        position: absolute;
                        width: 2px;
                        height: 36%;
                        background-color: grey;
                        left: calc(51.2%/1);
                        transform: translateX(-50%);
                    }

                    /* Style for the curved line */
                    .curve-marker::after {

                        content: "";
                        position: absolute;
                        width: 47%;
                        height: 2px;
                        background-color: grey;
                        bottom: 0;
                        top: 37.2px;
                        left: 57%;

                    }

                    .curve-marker-country::before {
                        height: 50%;
                        top: -18%;
                        left: calc(100%/1.344);
                    }

                    .curve-marker-country::after {
                        width: calc(100%/4);
                        left: calc(100%/1.3);

                    }

                    .curve-marker-state::before {
                        height: 50%;
                        top: -18%;
                        left: calc(100%/1.197);
                    }

                    .curve-marker-state::after {

                        width: calc(100%/9);
                        left: calc(100%/1.14);

                    }

                    .curve-marker-city::before {
                        height: 50%;
                        top: -18%;
                        left: calc(100%/1.149);
                    }

                    .curve-marker-city::after {
                        width: calc(100%/10);
                        left: calc(100%/1.12);
                    }

                    .curve-marker-local {

                        overflow: hidden;

                    }

                    .curve-marker-local::before {
                        height: 26%;
                        top: -18%;
                        left: calc(100%/1.098);
                    }

                    .curve-marker-local::after {
                        width: calc(100%/13);
                        left: calc(100%/1.08);
                        top: 43px;
                    }

                    .curve {
                        position: absolute;
                        width: 25px;
                        height: 25px;
                        background-color: transparent;
                        border-bottom: 2.5px solid grey;
                        border-bottom-left-radius: 50%;
                        left: 50.5%;
                        top: 15.6%;
                        border-left: 2.5px solid grey;
                    }

                    .curve-country {
                        left: calc(100%/1.35);
                    }

                    .curve-state {
                        left: calc(100%/1.2);
                    }

                    .curve-city {
                        left: calc(100%/1.15);
                    }

                    .curve-local {
                        left: calc(100%/1.1);
                        top: 20px;
                    }

                    .side-marker-continent {
                        position: relative;
                    }

                    .side-marker-continent::before {
                        content: "";
                        position: absolute;
                        width: 2px;
                        height: 100%;
                        background-color: grey;
                        left: 9.355%;
                        transform: translateX(-50%);
                    }

                    .side-marker-country {
                        position: relative;
                    }

                    .side-marker-country::before {
                        content: "";
                        position: absolute;
                        width: 2px;
                        height: 100%;
                        background-color: grey;
                        left: 25.35%;
                        transform: translateX(-50%);
                    }

                    .side-marker-state {
                        position: relative;
                    }

                    .side-marker-state::before {
                        content: "";
                        position: absolute;
                        width: 2px;
                        height: 100%;
                        background-color: grey;
                        left: 41.95%;
                        transform: translateX(-50%);
                    }

                    .side-marker-city {
                        position: relative;
                    }

                    .side-marker-city::before {
                        content: "";
                        position: absolute;
                        width: 2px;
                        height: 100%;
                        background-color: grey;
                        left: 8.355%;
                        transform: translateX(-50%);
                    }

                    .side-marker-local {
                        position: relative;
                    }

                    .side-marker-local::before {
                        content: "";
                        position: absolute;
                        width: 2.5px;
                        height: 100%;
                        background-color: grey;
                        left: 75.3%;
                        transform: translateX(-50%);
                    }


                    hr {
                        margin-top: 1rem;
                    }
                }
            }
        }

        .broadcast-history-outer {
            overflow: hidden;
            position: absolute;
            right: 0px;
            top: 0px;
            bottom: 0px;
            width: 350px;
            background: white;
            transition: all 0.5s;
            box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;

            .heading {
                padding: 15px;
                color: #1571ff;
                font-weight: bold;
                font-size: 19px;
                padding-left: 33px;
                box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
            }

            .collapse-btn-outer { 
                position: absolute;
                top: 11px;

                .collapse-btn {
                    color: #3e79e9;
                    background: none;
                    border: none;
                    font-size: 21px;
                }
            }

            .history-outer {
                background: #f5f7f9;

                .history-item {
                    margin: 15px;
                    border-radius: 10px;
                    padding: 10px;
                    background: white;

                    .header-outer {
                        display: flex;

                        .header-icon {
                            display: flex;
                            align-items: center;
                            text-align: center;
                            margin-right: 10px;

                            .name-icon {
                                width: 50px;
                                height: 50px;
                                border: 1px solid;
                                align-items: center;
                                border-radius: 50%;
                                display: flex;
                                text-align: center;
                                background: #469cf3;
                                color: white;

                                .name-icon-inner {
                                    margin: auto;
                                    font-size: 27px;
                                    font-weight: bold;
                                }
                            }
                        }

                        .header-detail {
                            .location {
                                color: #1a6dda;
                                font-weight: bold;
                            }
                            .user {
                                color: grey;
                                font-size: 12px;
                            }
                            .created-at {
                                color: grey;
                                font-size: 12px;
                            }
                        }
                    }

                    .message {
                        font-size: 14px;
                        padding: 10px;
                    }

                }
            }
        }

        .broadcast-history-outer-collapsed {
            width: 0px;
        }
    }
}



@media screen and (min-width: 320px) and (max-width: 480px) {
    .organisation-map-outer {
        .organisation-location-inner {
            display: none;
            .location-data-outer {
                .curve-marker {
                    display: none;
                }
                .curve-marker-mobile {
                    display: block;
                }
            }
        }
    }
}

@media screen and (min-width: 481px) and (max-width: 767.98px) {
    .organisation-map-outer {
        .organisation-location-inner {
            display: none;
            .location-data-outer {
                .curve-marker {
                    display: none;
                }
                .curve-marker-mobile {
                    display: block;
                }
            }
        }
    }
}

@media screen and (min-width: 768px) and (max-width: 991.98px) {
    .organisation-map-outer {
        .organisation-location-inner {
            display: none;
            .location-data-outer {
                .curve-marker {
                    display: none;
                }
                .curve-marker-mobile {
                    display: block;
                }
            }
        }
    }
}

@media screen and (min-width: 992px) and (max-width: 1200px) {}

@media screen and (min-width: 1201px) and (max-width: 1599px) {}