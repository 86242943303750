.layout-inner {

  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;

  .show-organisation-outer {
    flex-grow: 1;
    padding: 20px;
    margin: 0px 10px 20px 10px;

    .page-heading {
      text-align: center;
    }

    .left-data {
      display: flex;
      flex-direction: column;

      .layout-heading {
        padding: 10px;
        color: white;
        border-radius: 5px;
        overflow: hidden;
        position: relative;
        background: #5378fe;
        text-align: left;

        .banner-bg {
          height: 133px;
          position: absolute;
          right: 10px;
          top: -16px;

          img {
            height: 100%;
          }
        }
      }
    }

    .counter-outer {
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      background: white;
      margin: 15px 0px;

      .counter-heading {
        padding: 10px;
        font-size: 20px;
        font-weight: bold;
      }

      .employee-counter-outer {
        background: #fdcab3;
        border: 1px solid #fdcab3;
        border-radius: 5px;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        padding: 15px;
        margin: 10px;
        color: #cd5b27;

        .heading {
          font-size: 22px;
        }

        .count {
          font-size: 23px;
          color: white;
        }

        .sub-heading {
          color: #9c502c;
        }
      }

      .channel-counter-outer {
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        background: #bed7f9;
        border: 1px solid #bed7f9;
        border-radius: 5px;
        padding: 15px;
        margin: 10px;

        .heading {
          font-size: 22px;
          color: #276bca;
        }

        .count {
          font-size: 23px;
          color: white;
        }

        .sub-heading {
          color: #477fce;
        }
      }
    }

    .activities-outer {

      flex-grow: 1;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      background: white;
      border-radius: 5px;
      padding: 15px;
      display: flex;
      flex-direction: column;

      .title {
        font-size: 22px;
        font-weight: bold;
      }

      .no-activities {
        display: flex;
        align-items: center;
        flex-grow: 1;
        justify-content: center;
        color: grey;
      }
    }

    .organisation-info-outer {
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      background: white;
      border-radius: 5px;
      padding: 0px;
      overflow: hidden;

      .page-heading-outer {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
        background: #ecf7fd;
        padding: 10px;
        color: black;

        font-weight: bold;
        border-left: 5px solid #3c9de4;
        border-bottom: 1px solid #c1ddf1;

        .page-heading {
          font-size: 20px;

        }

        .edit-btn {
          background: none;
          border: none;
          font-size: 24px;
          color: rgb(0, 115, 255);
        }
      }

      .company-logo-outer {
        height: 60px;
        display: flex;
        align-items: center;
        width: 100%;
        padding: 0px 20px;
        border-radius: 10px;

        img {
          height: 100%;
          border-radius: 10px;
        }

        .organisation-name {
          font-weight: bold;
          font-size: 22px;

          border-radius: 10px;
          padding: 4px;

          margin: 10px;
        }
      }

      .company-details {
        padding: 20px;
        font-weight: 500;

        .label {
          white-space: nowrap;
          display: flex;
          justify-content: space-between;
          min-width: 118px;
          color: grey;

          float: left;
          margin-right: 5px;


        }

        div {
          line-break: auto;
          display: flex;
          text-align: left;

        }
      }

      .company-details>div {
        margin-bottom: 20px;
      }
    }

    .details-2-outer {
      margin: auto 20%;

      .label {
        font-weight: bold;
        font-size: 20px;
        color: #1746b1;
      }

      .data {
        font-weight: bold;
      }
    }
  }
}

@media (min-width: 320px) and (max-width: 479px) {
  .layout-inner {
    height: auto !important;

    .show-organisation-outer {
      .row {
        height: 100%;
        flex-direction: column;

        .left-data {
          width: auto;

          .layout-heading {
            h2 {
              font-size: calc(0.875rem + .3vw);
            }

            .heading {
              font-size: 12px;
            }

            .banner-bg {
              height: auto;
              width: 60px;
              position: absolute;
              right: 10px;
              top: 8px;
              img{
                width: 100%;
              }
            }
          }
        }
      }

      .counter-outer {
        .row {
          flex-direction: row;
          justify-content: center;
        }

        .employee-counter-outer {

          height: 105px;

          padding: 10px;
          margin: 10px;

          .heading {
            font-size: 12px;
          }

          .count {
            font-size: 12px;
          }

          .sub-heading {
            font-size: 10px;
          }
        }

        .channel-counter-outer {

          height: 105px;

          padding: 10px;
          margin: 10px;

          .heading {
            font-size: 12px;
          }

          .count {
            font-size: 12px;
          }

          .sub-heading {
            font-size: 10px;
          }
        }
      }

      .organisation-info-outer {
        margin-top: 16px;
        margin-left: 12px;
        margin-right: 12px;
        width: auto;
        text-align: center;
        font-size: 8px;
        font-weight: 800;

        .company-logo-outer {
          .company-logo {
            border-radius: 0px;
            height: 80%;
          }

          .organisation-name {
            margin-left: 25%;
          }

        }
      }
    }
  }
}

@media (min-width: 480px) and (max-width: 766px) {
  .row {

    display: flex;
    flex-direction: column;
  }

  .layout-inner {
    height: auto !important;

    .show-organisation-outer {
      .row {

        text-align: center;

        .left-data {
          width: 92%;
          margin-left: 32px;

          .counter-outer {
            .row {
              margin: 0px;
              display: flex;
              flex-direction: row;
              justify-content: space-evenly;
            }
          }
        }

        .organisation-info-outer {
          justify-content: center;
          min-width: 89%;
          text-align: center;
          font-size: 13px;
          margin-top: 16px;

          margin-left: 43px;
          font-weight: 600;
          display: flex;
          flex-direction: column;
          align-items: center;

          .page-heading-outer {
            width: 100%;
          }

          .company-logo-outer {
            justify-content: center;

            .company-logo {
              border-radius: 0px;
              height: 80%;
            }

            .organisation-name {
              margin-left: 25%;
            }
          }
        }
      }
    }
  }
}

@media (min-width: 767px) and (max-width: 1258px) {
  .show-organisation-outer {
    .left-data {
      width: 50%;

      .row {
        margin: 0px;
        text-align: center;
        justify-content: center;

        .employee-counter-outer {
          padding: 7px;
        }

        .channel-counter-outer {
          padding: 7px;
        }
      }
    }

    .organisation-info-outer {
      width: 50%;
    }
  }
}


@media (min-width:767px) and (max-width:1038px) {
  .show-organisation-outer {
    .row {
      height: 100%;
      display: flex;
      flex-direction: column;

      .left-data {
        width: auto;

        .counter-outer {
          display: flex;
          flex-direction: column;
          align-items: center;

          .employee-counter-outer {
            width: auto;
          }

          .channel-counter-outer {
            width: auto;
          }
        }
      }

      .organisation-info-outer {
        margin-top: 16px;
        width: 97%;
        margin-left: 11px;

        .company-logo-outer {
          display: flex;
          flex-direction: row;

        }

        .company-details {
          display: flex;
          flex-direction: column;
        }
      }
    }
  }
}


@media (min-width: 1259px) and (max-width: 1289px) {
  .show-organisation-outer {
    .row {
      display: flex;
      flex-direction: row;
      margin: 0px;

      justify-content: space-evenly;

      .left-data {
        flex: 1 1 60%;
        margin-right: 20px;
      }

      .organisation-info-outer {
        flex: 1 1 35%;
        display: flex;
        flex-direction: column;
        align-items: center;

        .page-heading-outer {
          width: 100%;
        }

        .company-details {
          display: flex;
          flex-direction: column;
          text-align: center;
        }
      }
    }
  }
}