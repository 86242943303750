.day-view {
  .day-events {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .event-item {
      background-color: white;
      padding: 1rem;
      border-radius: 8px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
      
      p {
        font-size: 1rem;
        font-weight: bold;
        margin: 0;
      }
      
      span {
        font-size: 0.85rem;
        color: #64748b;
      }
    }
  }
}
