.organisations-container {
  text-align: center;
  padding: 20px;

  h2 {
    font-size: 24px;
    margin-bottom: 20px;
    text-align: left;
  }

  .apps-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    justify-content: center;
    // display: flex;
      // flex-direction: column;

      
      // gap: 20px;

      .app-card {
        display: flex;
        align-items: center;
        cursor: pointer;
        background-color: #ffffff;
        border-radius: 10px;
        padding: 15px;
        width: 100%;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

        .app-icon {
          overflow: hidden;
          width: 20%;
          min-width: 100px;
          height: 60px;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 15px;
          img {
            width: 100%;
          }
        }

        .app-details {
          text-align: left;

          h3 {
            margin: 0;
            font-size: 18px;
            font-weight: 600;
          }

          p {
            margin: 5px 0 0 0;
            font-size: 14px;
            color: #6B7280;
          }
        }
      }
      .app-card:hover {
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      }
  }
}

@media screen and (min-width: 320px) and (max-width: 360px) {
  .organisations-container h2 {
    font-size: 20px;
  }

  .organisations-container .apps-grid {
    grid-template-columns: 1fr;
    gap: 15px;
  }

  .app-card {
    padding: 10px;
    flex-direction: column;

    .app-icon {
      width: 50px!important;
      height: 50px;
      margin-right: 10px;
    }

    .app-details h3 {
      font-size: 16px;
    }

    .app-details p {
      font-size: 12px;
    }
  }
}

@media screen and (max-width: 360px) {
  .organisations-container .apps-grid {
    grid-template-columns: repeat(1, 1fr);
    gap: 18px;
  }
}

@media screen and (min-width: 361px) and (max-width: 480px) {
  .organisations-container h2 {
    font-size: 22px;
  }

  .organisations-container .apps-grid {
    grid-template-columns: 1fr 1fr;
    grid-template-columns: repeat(1, 1fr);
    gap: 18px;
  }

  .app-card {
    padding: 10px;
    flex-direction: column;

    .app-icon {
      width: 50px!important;
      height: 50px;
      margin-right: 10px;
    }

    .app-details h3 {
      font-size: 17px;
    }

    .app-details p {
      font-size: 13px;
    }
  }
}

@media screen and (min-width: 481px) and (max-width: 575.98px) {
  .organisations-container h2 {
    font-size: 23px;
  }

  .organisations-container .apps-grid {
    grid-template-columns: repeat(1, 1fr);
    gap: 20px;
  }

  .app-card {
    padding: 10px;
    flex-direction: column;

    .app-icon {
      width: 50px!important;
      height: 50px;
      margin-right: 10px;
    }

    .app-details h3 {
      font-size: 18px;
    }

    .app-details p {
      font-size: 13.5px;
    }
  }
}

@media screen and (min-width: 576px) and (max-width: 771.2px) {
  .organisations-container h2 {
    font-size: 24px;
  }

  .organisations-container .apps-grid {
    grid-template-columns: repeat(1, 1fr);
    gap: 22px;
  }

  .app-card {
    padding: 16px;

    .app-icon {
      width: 60px;
      height: 60px;
      margin-right: 14px;
    }

    .app-details h3 {
      font-size: 19px;
    }

    .app-details p {
      font-size: 14px;
    }
  }
}

@media screen and (min-width: 1014px) and (max-width: 1200px) {
  .organisations-container h2 {
    font-size: 25px;
  }

  .organisations-container .apps-grid {
    grid-template-columns: repeat(3, 1fr);
    gap: 24px;
  }

  .app-card {
    padding: 18px;

    .app-icon {
      width: 65px;
      height: 65px;
      margin-right: 15px;
    }

    .app-details h3 {
      font-size: 20px;
    }

    .app-details p {
      font-size: 14.5px;
    }
  }
}

@media screen and (min-width: 1201px) {
  .organisations-container h2 {
    font-size: 26px;
  }

  .organisations-container .apps-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 26px;
  }

  .app-card {
    padding: 20px;

    .app-icon {
      width: 70px;
      height: 70px;
      margin-right: 16px;
    }

    .app-details h3 {
      font-size: 21px;
    }

    .app-details p {
      font-size: 15px;
    }
  }
}