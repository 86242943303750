.layout-inner {
  // height: 100%;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;

  .attendance-container-outer {
    flex-grow: 1;
    padding: 20px;
    overflow: scroll;
    margin: 20px;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    border: 1px solid rgb(223, 217, 217);
    // box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    background: white;

    // box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    // background: white;
    .nav-tabs {
      display: none;
    }

    .heading-outer {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      margin: 10px auto;
      padding: 10px;
      border-radius: 5px;

      .page-heading {
        font-size: 24px;
        font-weight: bold;
      }
    }

    .attendance-outer {
      width: 100%;
      // box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      margin: 10px auto;
      border-radius: 5px;
      padding: 10px;
      flex-grow: 1;
      overflow: scroll;
      display: flex;
      flex-direction: column;

      .summary-outer {
        .summary-inner {
          border-left: 6px solid #3d9ee3;
          display: flex;

          .first-sec {
            // width: 30%;
            .detail-outer {
              margin-left: 20px;
              padding: 20px;
              border: 1px solid #ececec;
              border-radius: 10px;
              box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
                rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;

              .icon-outer {
                margin-right: 10px;
              }

              .line-2 {
                margin: 10px 0px;
                font-size: 14px;
                color: grey;
              }

              .line-3 {
                font-size: 18px;
                font-weight: bold;
              }
            }
          }

          .second-sec {
    
            .detail-outer {
              margin-left: 20px;
              padding: 20px;
              border-radius: 10px;
              border: 1px solid #ececec;
              box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
                rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;

              .icon-outer {
                margin-right: 10px;
              }

              .line-2 {
                margin: 10px 0px;
                font-size: 14px;
                color: grey;
              }

              .line-3 {
                font-size: 18px;
                font-weight: bold;
              }
            }
          }

          .third-sec {
            // width: 40%;
            flex-grow: 0.5;

            .detail-outer {
              box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
                rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
              margin-left: 20px;
              border-radius: 10px;
              border: 1px solid #ececec;
              padding: 20px;
              height: 100%;
              display: flex;
              flex-direction: column;

              .graph-details-outer {
                display: flex;
                flex-grow: 1;

                .details {
                  flex: 0.5;
                  display: flex;
                  flex-direction: column;
                  justify-content: space-around;

                  .individual-item {
                    margin-bottom: 10px;
                    display: flex;
                    align-items: center;

                    .dot {
                      height: 13px;
                      width: 13px;
                      margin-right: 20px;
                      background-color: #bbb;
                      border-radius: 50%;
                      display: inline-block;
                    }

                    .line-tag {
                      margin-left: 5px;
                      color: grey;
                    }
                  }
                }

                .graph-inner {
                  flex: 0.5;
                  width: 150px;
                  height: 150px;
                  margin: auto;
                  display: flex;
                  align-items: center;
                  flex-direction: column;
                }
              }
            }
          }
        }
      }

      .history-outer {
        margin-top: 20px;

        .history-inner {
          margin-top: 20px;
          border-left: 6px solid #3d9ee3;
          padding: 0px 20px;

          .table-outer {
            border-radius: 10px;
            border: 1px solid #ececec;
            padding: 20px;
            box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
              rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;

            thead {
              tr {
                th {
                  background: #31a0ef;
                  color: white;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 320px) and (max-width: 479px) {
  .summary-outer {
    font-size: 16px !important;
    .summary-inner {
      display: flex;
      flex-direction: column;
      .first-sec {
        margin-top: 10px;
        .detail-outer {
          .line-2 {
            font-size: 12px !important;
          }
          .line-3 {
            font-size: 12px !important;
          }
        }
      }
      .second-sec {
        margin-top: 10px;
        .detail-outer {
          margin-top: 10px;
          .line-2 {
            font-size: 12px !important;
          }
          .line-3 {
            font-size: 12px !important;
          }
        }
      }
      .third-sec {
        .detail-outer {
          margin-top: 20px;
          .h5 {
            font-size: 16px !important;
          }
          .graph-details-outer {
            display: flex;
            flex-direction: column !important;
            .details {
              font-size: 10px;
              .graph-inner {
                width: 130px;
                height: 130px;
              }
            }
          }
        }
      }
    }
  }
  
  .history-outer {
    .h4 {
      font-size: 16px;
    }
    .history-inner {
      .table-outer {
        font-size: 10px;
      }
    }
  }
}

@media (min-width: 480px) and (max-width: 766px) {
  .summary-inner {
    display: flex;
    flex-direction: column;
    width: 100%;
    .first-sec {
      margin-top: 20px;
      display: flex;
        justify-content: space-evenly;
      height: 100%;
      .detail-outer {
        margin-right: 10px;
        width: 50%;
        text-align: center;
        margin-bottom: 0px !important;
      }
    }

    .second-sec {
      margin-top: 20px;
      display: flex;
    //   justify-content: space-evenly;
      height: 100%;
      margin-right: 10px;
      .detail-outer {
        // width: 31%;
        text-align: center;
        width: 50%;
        margin-left: 27px!important;
        margin-bottom: 0px !important;
        .line-2 {
          font-size: 12px !important;
        }
        .line-3 {
          font-size: 12px !important;
        }
      }
    }

    .third-sec {
      text-align: center;
      margin-top: 20px;
      width: 98%;
      //   margin-left: 2px;
    }
  }
}

@media (min-width: 767px) and (max-width: 840px) {
    .summary-inner{
        .first-sec{
            .detail-outer{
                padding: 10px!important;
            }
        }
    }
}

@media (min-width: 841) and (max-width: 948px) {
    .summary-inner{
        .first-sec{
            .detail-outer{
                padding: 10px!important;
            }
        }
        .second-sec{
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }
}
