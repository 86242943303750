.week-view {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  .week-header {
    text-align: center;
    margin-bottom: 1rem;
    font-size: 1.5rem;
    color: #333;
    font-weight: bold;
  }

  .week-container {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  /* Date headers - fixed at the top */
  .week-date-headers {
    display: flex;
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 10;
   
    border-bottom: 1px solid #e0e0e0;

    .day-header {
      flex: 1;
      text-align: center;
      padding: 10px 0;
      font-weight: bold;
      font-size: 1rem;
      color: #333;
    }

    .time-column-header {
      width: 60px; /* Same width as the time column */
    }
  }

  /* Scrollable section for the events */
  .week-events-scrollable {
    display: flex;
    flex-grow: 1;
    overflow-y: auto;
    padding-top: 10px;

    .time-column {
      width: 60px;
      display: flex;
      flex-direction: column;
      text-align: right;
      padding-right: 1rem;
      font-size: 0.85rem;
      color: #333;

      .time-slot {
        height: 60px;
        border-top: 1px solid #e0e0e0;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-right: 0.5rem;
      }
    }

    .days-events {
      display: flex;
      flex-grow: 1;

      .day-column {
        flex: 1;
        display: flex;
        flex-direction: column;
        border-left: 1px solid #e0e0e0;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        position: relative;

        .day-events {
          position: relative;
          height: 1440px; /* 24 hours * 60 minutes */
          border-top: 1px solid #e0e0e0;

          .event-item {
            position: absolute;
            width: 90%;
            background-color: #f0f8ff;
            border-radius: 10px;
            padding: 0.5rem;
            box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
            z-index: 2;
            overflow: hidden;

            p {
              font-size: 0.9rem;
              font-weight: bold;
              margin: 0;
              color: #333;
            }

            span {
              font-size: 0.8rem;
              color: #666;
            }

            &.meeting {
              background-color: rgba(223, 229, 255, 0.9);
              border-left: 5px solid #4a90e2;
            }

            &.event {
              background-color: rgba(255, 241, 220, 0.9);
              border-left: 5px solid #f5a623;
            }

            &.announcement {
              background-color: rgba(220, 248, 240, 0.9);
              border-left: 5px solid #7ed321;
            }
          }
        }
      }
    }
  }
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
  .week-date-headers,
  .week-events-scrollable {
    flex-direction: column;
  }

  .day-header {
    font-size: 0.85rem;
  }

  .time-column .time-slot {
    font-size: 0.75rem;
  }

  .event-item {
    font-size: 0.75rem;
    padding: 8px;
  }
}
