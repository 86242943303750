.add-invite-member-share-link-popup-content {

    .add-invite-member-share-link-outer {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        overflow: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;
        .popup-heading {
            
            color: black;
            display: flex;
            align-items: center;
            gap: 20px;
            text-align: left;
            margin-bottom: 20px;
            .popup-heading-icon {
                height: 50px;
                width: 50px;
                display: flex;
                align-items: center;
                text-align: center;
                border-radius: 50%;
                background: #eaf5fd;
                svg {
                    margin: auto;
                    color: #31a0ef;
                }
            }
            .popup-heading-inner {
                line-height: 22px;
                .popup-heading-1 {
                    font-size: 25px;
                    font-weight: 600;
                }
                .popup-heading-2 {
                    color: grey;
                    font-weight: 500;
                    font-size: 16px;
                }
            }
        }
        .popup-content-inner {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            overflow: scroll;
            -ms-overflow-style: none;
            scrollbar-width: none;
            -ms-overflow-style: none;
            scrollbar-width: none;
            
            .share-link-inner {
                display: flex;
                justify-content: center;
                align-items: center;
                border: 1px solid #CCCCCC;
                border-radius: 5px;
                padding: 5px;
                .link-icon {
                    font-size: 30px;
                    color: #808080;
                }
                .link {
                   
                    padding: 5px 10px;
                }
                .link-copy-btn {
                    white-space: nowrap;
                    border: none;
                    background: transparent;
                    color: #808080;
                    font-weight: 600;
                    font-size: 18px;
                }
            }
        }
    }
}

@media (max-width: 520px) {
    .add-invite-member-share-link-popup-content {
      .add-invite-member-share-link-outer {
        .popup-heading {
            padding: 0px 10px;
            gap: 10px;
            display: flex;
            flex-direction: column;
            text-align: center;
          .popup-heading-icon {
            height: 40px; // Reduce icon size
            width: 40px;
  
            svg {
              font-size: 20px; // Adjust icon size
            }
          }
  
          .popup-heading-inner {
            .popup-heading-1 {
              font-size: 20px; // Reduce heading font size
            }
  
            .popup-heading-2 {
              font-size: 14px; // Reduce subheading font size
            }
          }
        }
  
        .popup-content-inner {
          padding: 10px; // Adjust inner content padding
          .share-link-inner {
            flex-direction: column; // Stack elements vertically on smaller screens
            align-items: flex-start;
  
            .link {
              font-size: 14px; // Adjust font size
              word-break: break-word; // Break the long link text if necessary
            }
  
            .link-copy-btn {
              font-size: 16px; 
              color: #127ae2;
            }
          }
        }
      }
    }
    .popup-content {
        margin: auto;
        background: #fff;
        width: 72%;
        padding: 5px;
        border: 1px solid #d7d7d7;
    }
    .add-invite-member-share-link-popup-content .add-invite-member-share-link-outer .popup-content-inner .share-link-inner .link-icon {
        font-size: 22px;
        color: #158dd6;
    }
  }

  
