.manage-channel-popup-content {

    .manage-channel-outer {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        overflow: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;
        .popup-content-inner {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            overflow: scroll;
            -ms-overflow-style: none;
            scrollbar-width: none;
            .channel-name-outer {
                .name-label {}

                .channel-name-input {
                    border: none;
                    border-bottom: 1px solid grey;
                    width: 100%;
                    font-weight: bold;
                    color: rgb(59, 59, 59)
                }

                .channel-name-input:focus-visible {
                    outline: none;
                }
            }

            .member-select-outer {
                margin-top: 20px;
                flex-grow: 1;
                display: flex;
                flex-direction: column;
                overflow: scroll;
                -ms-overflow-style: none;
                scrollbar-width: none;

                .member-select-label {
                    font-weight: bold;
                }

                .members-list {
                    flex-grow: 1;
                    overflow: scroll;
                    -ms-overflow-style: none;
                    scrollbar-width: none;
                    .member-select-item-outer {
                        margin: 10px;
                        display: flex;
                        align-items: center;

                        .member-select-checkbox {
                            height: 18px;
                            width: 18px;
                        }

                        .member-select-item-label {
                            margin-left: 20px;

                            .member-name {
                                color: #5199ff;
                                font-weight: bold;
                            }
                        }
                    }
                }
                .channel-details-table {
                    width: 100%;
                    .action-btn {
                        border: none;
                        border-radius: 5px;
                        background: rgb(87, 166, 250);
                        color: white;
                        font-weight: bold;
                    }
                    .action-btn-disabled {
                        background: rgb(205, 208, 212);
                    }
                }
            }
        }
    }
}