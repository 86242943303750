.main-event-part {
  margin: 30px;
  background-color: #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 30px;

  .upper-event-part {
    border-bottom: 1px solid rgba(234, 234, 234, 1);
    padding-bottom: 10px;
    text-align: left;
    margin-bottom: 20px;

    .event-name {
      font-size: 24px;
      font-weight: bold;
      color: #333;
    }

    .event-purpose {
      font-size: 14px;
      color: #808080;
      margin-top: 5px;
    }
  }

  .lower-event-part {
    width: 55%;
    .event-input {
      margin-bottom: 20px;

      label {
        font-size: 14px;
        font-weight: bold;
        color: #333;
        margin-bottom: 5px;
        display: block;
      }

      .input-with-icon {
        display: flex;
        align-items: center;
        padding: 10px;
        border: 1px solid #eaeaea;
        border-radius: 8px;
        // background-color: #f9f9f9;textarea
        .input-field-add-event {
          display: flex;
          align-items: center;
          gap: 10px;
          width: 100%;
          .input-add-event {
            display: flex;
            gap: 10px;
            align-items: center;
            width: 100%;
          }
        }
        .input-icon {
          margin-right: 10px;
          color: #808080;
        }

        .input-with-icon input {
          flex-grow: 1;
        }

        .add-button {
          margin-left: 10px;
          padding: 3.5px 16px;
          background-color: rgba(234, 234, 234, 1); /* Customize the color */
          color: rgba(128, 128, 128, 1);
          border: none;
          border-radius: 4px;
          cursor: pointer;
          font-size: 14px;
          font-weight: 400;
        }

        .add-button:hover {
          background-color: rgba(
            128,
            128,
            128,
            1
          ); /* Customize the hover color */
          color: rgba(234, 234, 234, 1);
        }

        input,
        textarea {
          flex-grow: 1;
          border: none;
          outline: none;
          background-color: transparent;
          font-size: 14px;
          color: #333;
        }

        input::placeholder,
        textarea::placeholder {
          color: #bbb;
        }
      }

      textarea {
        width: 100%;
        border: 1px solid #eaeaea;
        resize: none;
        border-radius: 8px;
        padding: 10px;
        height: 100px;
        outline: none;
      }

      .input-with-icons {
        display: flex;
        justify-content: start;

        .date,
        .time {
          width: 50%;
          display: flex;
          align-items: center;
          border: 1px solid #eaeaea;
          padding: 10px;
          border-radius: 8px;
          // background-color: #f9f9f9;
          // flex: 1;
          margin-right: 10px;

          input {
            border: none;
            outline: none;
            background-color: transparent;
            font-size: 14px;
            width: 100%;
          }

          .input-icon {
            margin-left: 10px;
            color: #808080;
          }
          .error-message-section{
            width: 50%;
            color: red;
          }
        }

        .time {
          margin-right: 0;
        }
      }

      .upload-section {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 20px;
        border: 2px dashed #eaeaea;
        border-radius: 8px;
        // background-color: #f9f9f9;
        text-align: center;
        cursor: pointer;

        .upload-icon {
          font-size: 24px;
          color: #808080;
        }

        .upload-content {
          margin-left: 15px;
          display: flex;
          flex-direction: column;
          color: rgba(128, 128, 128, 1);

          .upload-text {
            font-size: 14px;
            font-weight: bold;
            color: #333;
          }

          span {
            font-size: 12px;
            color: #808080;
          }
        }

        input {
          display: none;
        }
      }

      .uploaded-files {
        margin-top: 15px;

        .file-item {
          display: flex;
          align-items: center;
          background-color: #f5f5f5;
          border: 1px solid #eaeaea;
          padding: 10px;
          border-radius: 5px;
          margin-bottom: 10px;

          .file-details {
            margin-left: 10px;

            span:first-child {
              font-weight: bold;
              color: #333;
            }

            span:last-child {
              font-size: 12px;
              color: #808080;
            }
          }
        }
      }
    }

    .attachment-container {
      flex-direction: column;
      max-width: 100%;
      display: flex;

      .attachment-value {
        visibility: hidden;
        position: absolute;
      }
    }

    .members-images {
      display: flex;
      align-items: center;
      margin-top: 10px;

      img {
        width: 35px;
        height: 35px;
        border-radius: 50%;
        margin-right: 5px;
        border: 2px solid #fff;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
      }

      .member-count {
        font-size: 14px;
        color: #808080;
      }
    }

    .event-actions {
      display: flex;
      justify-content: flex-end;
      margin-top: 30px;

      .cancel-btn,
      .create-btn {
        padding: 10px 20px;
        border-radius: 8px;
        border: none;
        font-size: 14px;
        cursor: pointer;
      }

      .cancel-btn {
        background-color: #eaeaea;
        color: #333;
        margin-right: 10px;
      }

      .cancel-btn:hover {
        background-color: rgba(
          128,
          128,
          128,
          1
        ); /* Customize the hover color */
        color: rgba(234, 234, 234, 1);
      }

      .create-btn {
        background-color: #32a0ef;
        color: #fff;
      }

      .create-btn:hover {
        background-color: #0092fa; /* Customize the hover color */
        color: rgba(234, 234, 234, 1);
      }
    }
  }

  .members-images {
    display: flex;
    margin-top: 10px;
    align-items: center;
    .member-image-wrapper {
      position: relative;
      margin-right: 8px;
      .member-profile-image {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        object-fit: cover;
      }
      .remove-icon {
        position: absolute;
        top: 0px;
        right: 0px;
        background-color: rgba(234, 234, 234, 1);
        border-radius: 50%;
        width: 15px;
        height: 15px;
        padding: 2px;
        font-size: 10px;
        color: rgba(128, 128, 128, 1);
        cursor: pointer;
      }
    }
    .main-member-count {
      background-color: rgba(234, 234, 234, 1);
      color: rgba(128, 128, 128, 1);
      border-radius: 50%;
      display: flex;
      width: 40px;
      height: 40px;
      padding: 5px;
      object-fit: cover;
      .member-count {
        font-size: 16px;
        font-weight: bold;
        align-items: center;
        margin: auto;
        position: relative;
      }
    }
  }

  .event-actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;

    .cancel-btn,
    .create-button {
      padding: 10px 20px;
      border-radius: 8px;
      border: none;
      font-size: 14px;
      cursor: pointer;
    }

    .cancel-btn {
      background-color: #eaeaea;
      color: #333;
      margin-right: 10px;
    }

    .create-button {
      background-color: #32a0ef;
      color: #fff;

      &:hover {
        background-color: #0394fc;
      }

      &:disabled {
        background-color: #eaeaea;
        cursor: default;
      }
    }
  }
}

.members-profile-picture {
  width: 50px;
}

.input-error {
  border: 2px solid red; // Red border for invalid input
}

.error-message {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}

@media (max-width: 768px) {
  .main-event-part {
    padding: 20px;
    width: 90%;
    margin: auto;
    margin-top: 20px;

    .end-start-event {
      flex-direction: column;
    }

    .lower-event-part {
      width: 100%;
    }
  }

  .input-with-icons {
    // flex-direction: column;
  }

  .upload-section {
    flex-direction: column;
    .upload-content {
      margin-top: 10px;
    }
  }
}
