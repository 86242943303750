.layout-inner-member-details {
  display: flex;
  flex-direction: column;
  padding: 30px;
  gap: 20px;
  .current-page {
    display: flex;
    .key {
      color: rgba(102, 102, 102, 1);
      font-size: 16px;
      .arrow-image {
        img {
        }
      }
    }
    .value {
      font-size: 16px;
      font-weight: 600;
      color: rgba(102, 102, 102, 1);
    }
  }
  .member-detail-container-outer {
    background-color: rgba(255, 255, 255, 1);
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    .member-details-part-one {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      .left-part {
        display: flex;
        align-items: center;
        gap: 10px;
        .member-picture {
          width: 100px;
          height: 100px;
          border-radius: 50%;
          overflow: hidden;
          img {
            width: 100%;
          }
        }
        .name-designation {
          display: flex;
          flex-direction: column;
          .member-name-header {
            color: rgba(51, 51, 51, 1);
            font-size: 24px;
            font-weight: 600;
          }
          .member-designation-header {
            color: rgba(128, 128, 128, 1);
            font-size: 15px;
          }
        }
      }
      .right-part {
        display: flex;
        align-items: center;
        .deactivate-btn {
          background-color: rgba(255, 59, 48, 1);
          color: rgba(255, 255, 255, 1);
          display: flex;
          align-items: center;
          gap: 5px;
          border: none;
          border-radius: 5px;
          padding: 5px 15px;
          .dustbin {
            display: flex;
            align-items: center;
            .img {
            }
          }
        }
      }
    }
    .member-details-part-two {
      border: 1px solid rgba(50, 160, 239, 0.6);
      border-radius: 5px;
      overflow: hidden;
      .basic-info {
        .heading-basic-info {
          background-color: rgba(235, 245, 253, 1);
          color: rgba(51, 51, 51, 1);
          font-size: 16px;
          font-weight: 800px;
          padding: 10px;
        }
        .keys-values {
          display: flex;
          gap: 50px;
          padding: 10px;
          .column-keys-values {
            display: flex;
            flex-direction: column;
            gap: 20px;
            .keys-values-section {
              display: flex;
              flex-direction: column;
              .key {
                color: rgba(102, 102, 102, 1);
                font-size: 14px;
              }
              .value {
                color: rgba(51, 51, 51, 1);
                font-size: 16px;
              }
            }
          }
        }
      }
    }
    .member-details-part-three {
      display: grid;
      grid-template-columns: repeat(3, 1fr); // Three equal-width columns
      gap: 20px;
      @media (max-width: 768px) {
        grid-template-columns: 1fr; // Single-column layout for smaller screens
      }
      .left-div-three {
        display: grid;
        grid-template-rows: repeat(2, 1fr);
        gap: 20px;
        .left-upper-div {
          border: 1px solid rgba(50, 160, 239, 0.6);
          border-radius: 5px;
          overflow: hidden;
          .heading-education {
            background-color: rgba(235, 245, 253, 1);
            color: rgba(51, 51, 51, 1);
            font-size: 16px;
            font-weight: 800px;
            padding: 10px;
          }
          .education-data {
            padding: 10px;
            .history {
              display: flex;
              gap: 10px;
              .left-div {
                .icon-bachelor {
                  width: 30px;
                  height: 30px;
                  img {
                    width: 100%;
                  }
                }
                .icon-master {
                  width: 30px;
                  height: 30px;
                  img {
                    width: 100%;
                  }
                }
              }
              .right-div {
                .course {
                  color: rgba(51, 51, 51, 1);
                  font-size: 16px;
                  font-weight: 600;
                }
                .organization-name {
                  color: rgba(51, 51, 51, 1);
                  font-size: 14px;
                  font-weight: 500;
                }
                .passing-year {
                  .key {
                    color: rgba(128, 128, 128, 1);
                    font-size: 13px;
                  }
                  .value {
                    color: rgba(128, 128, 128, 1);
                    font-size: 13px;
                  }
                }
                .score {
                  .key {
                    color: rgba(128, 128, 128, 1);
                    font-size: 14px;
                  }
                  .value {
                    color: rgba(128, 128, 128, 1);
                    font-size: 14px;
                  }
                }
              }
            }
          }
        }
        .left-lower-div {
          border: 1px solid rgba(50, 160, 239, 0.6);
          border-radius: 5px;
          overflow: hidden;
          .heading-certifications {
            background-color: rgba(235, 245, 253, 1);
            color: rgba(51, 51, 51, 1);
            font-size: 16px;
            font-weight: 800px;
            padding: 10px;
          }
          .certification-data {
            padding: 10px;
            .history {
              display: flex;
              gap: 10px;
              .left-div {
                .icon-certification {
                  width: 30px;
                  height: 30px;
                  img {
                    width: 100%;
                  }
                }
              }
              .right-div {
                .certified-course {
                  color: rgba(51, 51, 51, 1);
                  font-size: 16px;
                  font-weight: 600;
                }
                .issued-year {
                  .key {
                    color: rgba(128, 128, 128, 1);
                    font-size: 13px;
                  }
                  .value {
                    color: rgba(128, 128, 128, 1);
                    font-size: 13px;
                  }
                }
                .certified-id {
                  .key {
                    color: rgba(128, 128, 128, 1);
                    font-size: 13px;
                  }
                  .value {
                    color: rgba(128, 128, 128, 1);
                    font-size: 13px;
                  }
                }
              }
            }
          }
        }
      }
      .middle-div-three {
        display: grid;
        grid-template-rows: repeat(2, 1fr);
        gap: 20px;
        .middle-upper-div {
          border: 1px solid rgba(50, 160, 239, 0.6);
          border-radius: 5px;
          overflow: hidden;
          .heading-experience {
            background-color: rgba(235, 245, 253, 1);
            color: rgba(51, 51, 51, 1);
            font-size: 16px;
            font-weight: 800px;
            padding: 10px;
          }
          .experience-data {
            padding: 10px;
            display: flex;
            flex-direction: column;
            gap: 10px;
            .history {
              display: flex;
              align-items: baseline;
              gap: 10px;
              .left-div {
                display: flex;
                flex-direction: column;
                gap: 16px;
                // border-left: 1.5px solid rgba(207, 234, 255, 1);
                .icon-experience {
                  .ordered-list-dot {
                    width: 10px;
                    height: 10px;
                    border-radius: 50%;
                    background-color: rgba(50, 160, 239, 1);
                  }
                }
              }
              .right-div {
                .designation {
                  color: rgba(51, 51, 51, 1);
                  font-size: 16px;
                  font-weight: 600;
                }
                .organization-name {
                  color: rgba(51, 51, 51, 1);
                  font-size: 14px;
                  font-weight: 500;
                }
                .working-year {
                  color: rgba(128, 128, 128, 1);
                  font-size: 13px;
                }
                .location {
                  color: rgba(128, 128, 128, 1);
                  font-size: 13px;
                }
              }
            }
          }
        }
        .middle-lower-div {
          border: 1px solid rgba(50, 160, 239, 0.6);
          border-radius: 5px;
          overflow: hidden;
          .heading-awardsrecognitions {
            background-color: rgba(235, 245, 253, 1);
            color: rgba(51, 51, 51, 1);
            font-size: 16px;
            font-weight: 800px;
            padding: 10px;
          }
          .awardsrecognition-data {
            padding: 10px;
            .history {
              display: flex;
              gap: 10px;
              .left-div {
                .icon-employee-month {
                  width: 30px;
                  height: 30px;
                  img {
                    width: 100%;
                  }
                }
              }
              .right-div {
                .title {
                  color: rgba(51, 51, 51, 1);
                  font-size: 16px;
                  font-weight: 600;
                }
                .event-name {
                  color: rgba(51, 51, 51, 1);
                  font-size: 14px;
                  font-weight: 500;
                }
                .description {
                  color: rgba(128, 128, 128, 1);
                  font-size: 13px;
                }
              }
            }
          }
        }
      }
      .right-div-three {
        display: grid;
        grid-template-rows: repeat(2, 1fr);
        gap: 20px;
        .right-upper-div {
          border: 1px solid rgba(50, 160, 239, 0.6);
          border-radius: 5px;
          overflow: hidden;
          .heading-skills {
            background-color: rgba(235, 245, 253, 1);
            color: rgba(51, 51, 51, 1);
            font-size: 16px;
            font-weight: 800px;
            padding: 10px;
          }
          .skills-data {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            gap: 10px;
            padding: 10px;
            .skill-list {
              border: 1px solid rgba(50, 160, 239, 0.6);
              border-radius: 15px;
              overflow: hidden;
              display: flex;
              align-items: center;
              font-size: 12px;
              justify-content: center;
              padding: 2px 10px;
              // width: 50%;
            }
          }
        }
        .right-middle-div {
          border: 1px solid rgba(50, 160, 239, 0.6);
          border-radius: 5px;
          overflow: hidden;
          .heading-personalwebsite {
            background-color: rgba(235, 245, 253, 1);
            color: rgba(51, 51, 51, 1);
            font-size: 16px;
            font-weight: 800px;
            padding: 10px;
          }
          .website-data {
            padding: 10px;
            .history {
              display: flex;
              gap: 10px;
              .left-div-web {
                .icon-globe {
                  width: 30px;
                  height: 30px;
                  img {
                    width: 100%;
                  }
                }
              }
              .right-div-web {
                display: flex;
                flex-direction: column;
                .type {
                  color: rgba(51, 51, 51, 1);
                  font-size: 16px;
                  font-weight: 600;
                }
                .website-url {
                  color: rgba(51, 51, 51, 1);
                  font-size: 14px;
                  font-weight: 500;
                }
              }
            }
          }
        }
        .right-lower-div {
          border: 1px solid rgba(50, 160, 239, 0.6);
          border-radius: 5px;
          overflow: hidden;
          .heading-languages {
            background-color: rgba(235, 245, 253, 1);
            color: rgba(51, 51, 51, 1);
            font-size: 16px;
            font-weight: 800px;
            padding: 10px;
          }
          .languages-data {
            padding: 10px;
            .languages-list {
              display: flex;
              flex-direction: column;
              gap: 10px;
              .namelevel {
                display: flex;
                gap: 10px;
                align-items: center;
                .listitem-dot {
                  width: 10px;
                  height: 10px;
                  border-radius: 50%;
                  background-color: rgba(50, 160, 239, 1);
                }
                .lang-name-level {
                  .language-name {
                    color: rgba(51, 51, 51, 1);
                    font-size: 16px;
                    font-weight: 600;
                  }
                  .language-level {
                    color: rgba(128, 128, 128, 1);
                    font-size: 14px;
                    font-weight: 500;
                  }
                }
              }
            }
          }
        }
      }
    }
    .member-details-part-four {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 20px;
      .conference-event {
        border: 1px solid rgba(50, 160, 239, 0.6);
        border-radius: 5px;
        overflow: hidden;
        .heading-conference-event {
          background-color: rgba(235, 245, 253, 1);
          color: rgba(51, 51, 51, 1);
          font-size: 16px;
          font-weight: 800px;
          padding: 10px;
        }
        .conference-event-data {
          padding: 10px;
          .history {
            .left-div {
              .icon-conference {
                .ordered-list-dot {
                }
              }
            }
            .right-div {
              .title {
                color: rgba(51, 51, 51, 1);
                font-size: 18px;
                font-weight: 600;
              }
              .topic-name {
                display: flex;
                gap: 5px;
                .key {
                  color: rgba(51, 51, 51, 1);
                  font-size: 16px;
                  font-weight: 600;
                }
                .value {
                  color: rgba(51, 51, 51, 1);
                  font-size: 16px;
                  font-weight: 400;
                }
              }
              .event-date {
                display: flex;
                gap: 5px;
                .key {
                  color: rgba(128, 128, 128, 1);
                  font-size: 14px;
                  font-weight: 600;
                }
                .value {
                  color: rgba(128, 128, 128, 1);
                  font-size: 14px;
                  font-weight: 400;
                }
              }
              .venue {
                display: flex;
                gap: 5px;
                .key {
                  color: rgba(128, 128, 128, 1);
                  font-size: 14px;
                  font-weight: 600;
                }
                .value {
                  color: rgba(128, 128, 128, 1);
                  font-size: 14px;
                  font-weight: 400;
                }
              }
            }
          }
        }
      }
      .projects {
        border: 1px solid rgba(50, 160, 239, 0.6);
        border-radius: 5px;
        overflow: hidden;
        .heading-projects {
          background-color: rgba(235, 245, 253, 1);
          color: rgba(51, 51, 51, 1);
          font-size: 16px;
          font-weight: 800px;
          padding: 10px;
        }
        .projects-data {
          display: flex;
          flex-direction: column;
          gap: 10px;
          padding: 10px;
          .history {
            display: flex;
            align-items: baseline;
            gap: 10px;
            .left-div {
              .icon-projects {
                .ordered-list-dot {
                  width: 10px;
                  height: 10px;
                  border-radius: 50%;
                  background-color: rgba(50, 160, 239, 1);
                }
              }
            }
            .right-div {
              .title {
                color: rgb(51, 51, 51);
                font-size: 18px;
                font-weight: 600;
              }
              .description {
                color: rgb(51, 51, 51);
                font-size: 16px;
                font-weight: 400;
              }
            }
          }
        }
      }
    }
    .member-details-part-five {
      border: 1px solid rgba(50, 160, 239, 0.6);
      border-radius: 5px;
      overflow: hidden;
      .profiles {
        .heading-profiles {
          background-color: rgba(235, 245, 253, 1);
          color: rgba(51, 51, 51, 1);
          font-size: 16px;
          font-weight: 800px;
          padding: 10px;
        }
        .profiles-data {
          padding: 10px;
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 20px;
          .history {
            border: 1px solid rgba(50, 160, 239, 0.6);
            border-radius: 5px;
            overflow: hidden;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 10px;
            padding: 10px;
            .left-div {
              padding: 10px;
              display: flex;
              flex-direction: column;
              align-items: center;
              gap: 5px;
              .user-profile-image {
                width: 60px;
                height: 60px;
                border-radius: 50%;
                overflow: hidden;
                img {
                  width: 100%;
                }
              }
              .user-name {
                color: rgba(51, 51, 51, 1);
                font-size: 16px;
                font-weight: 600;
                text-align: center;
              }
              .user-designation {
                color: rgba(128, 128, 128, 1);
                font-size: 14px;
                font-weight: 500;
              }
            }
            .right-div {
              background-color: rgba(236, 247, 255, 1);
              padding: 10px;
              border-radius: 10px;
              overflow: hidden;
              .phone-number {
                display: flex;
                gap: 10px;
                align-items: center;
                .phone-icon {
                  img {
                  }
                }
                .number {
                  color: rgba(128, 128, 128, 1);
                  font-size: 14px;
                  font-weight: 500;
                }
              }
              .email {
                display: flex;
                gap: 10px;
                align-items: center;
                .email-icon {
                  img {
                  }
                }
                .email-details {
                  color: rgba(128, 128, 128, 1);
                  font-size: 14px;
                  font-weight: 500;
                }
              }
              .view-details {
                button {
                  background-color: transparent;
                  border: none;
                  .arrow {
                    img {
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 320px) and (max-width: 480px) {
}

@media screen and (max-width: 575.98px) {
  .layout-inner-member-details {
    .member-detail-container-outer {
      .member-details-part-one {
        flex-direction: column;
        align-items: center;
        gap: 20px;
        .left-part {
          .member-picture {
            width: 80px;
            height: 80px;
          }
          .name-designation {
            .member-name-header {
              font-size: 22px;
            }
          }
        }
        .right-part {
          .deactivate-btn {
            padding: 2px 5px;
            font-size: 14px;
          }
        }
      }
      .member-details-part-two {
        .basic-info {
          .keys-values {
            flex-direction: column;
            gap: 20px;
          }
        }
      }
      .member-details-part-three {
      }
      .member-details-part-four {
        grid-template-columns: 1fr;
      }
      .member-details-part-five {
        .profiles {
          .profiles-data {
            grid-template-columns: 1fr;
            .history {
              display: block;
              .left-div {
                text-align: center;
                .user-name {
                  font-size: 14px;
                }
              }
              .right-div{
                .phone-number{
                  .phone-icon{
                    img{

                    }
                  }
                  .number{
                    // max-width: 150px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                  }
                }
                .email{
                  .email-icon{
                    img{

                    }
                  }
                  .email-details{
                    // max-width: 150px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 576px) and (max-width: 767.98px) {
  .layout-inner-member-details {
    .member-detail-container-outer {
      .member-details-part-one {
        flex-direction: column;
        align-items: center;
        gap: 20px;
        .left-part {
          .member-picture {
            width: 80px;
            height: 80px;
          }
          .name-designation {
            .member-name-header {
              font-size: 22px;
            }
          }
        }
        .right-part {
          .deactivate-btn {
            padding: 2px 5px;
            font-size: 14px;
          }
        }
      }
      .member-details-part-two {
        .basic-info {
          .keys-values {
            flex-direction: column;
            gap: 20px;
          }
        }
      }
      .member-details-part-three {
      }
      .member-details-part-four {
        grid-template-columns: 1fr;
      }
      .member-details-part-five {
        .profiles {
          .profiles-data {
            grid-template-columns: 1fr;
            .history {
              grid-template-columns: 1fr;
              .left-div {
                text-align: center;
                .user-name {
                  font-size: 14px;
                }
              }
              .right-div{
                text-align: center;
                .phone-number{
                  justify-content: center;
                }
                .email{
                  justify-content: center;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 991.98px) {
}

@media screen and (min-width: 992px) and (max-width: 1200px) {
}

@media screen and (min-width: 1201px) and (max-width: 1599px) {
}
